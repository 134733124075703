import { NoticeIconData } from '@/components/NoticeIcon';
import { getAppManageList } from '@/services/metadata';
import { getStore, getPageQuery } from '@/utils/utils';
import { Subscription } from 'dva';
import { Reducer } from 'redux';
import { Effect } from './connect.d';
import { map } from 'lodash';

export interface NoticeItem extends NoticeIconData {
  id: string;
  type: string;
  status: string;
}

export interface IClientConfigItem {
  product_name: string;
  product_id: number;
  status: 0 | 1;
}

export interface GlobalModelState {
  collapsed: boolean;
  notices: NoticeItem[];
  query: AnyObj;
  product_id: number;
  curMenu: AnyObj;
  clientConfig?: IClientConfigItem[];
  basePath: [];
  breadcrumbList: [];
  noPage: string;
}

export interface GlobalModelType {
  namespace: 'global';
  state: GlobalModelState;
  effects: {
    fetchClientConfig: Effect;
    clearNotices: Effect;
    changeNoticeReadState: Effect;
  };
  reducers: {
    saveQuery: Reducer<GlobalModelState>;
    changeLayoutCollapsed: Reducer<GlobalModelState>;
    saveNotices: Reducer<GlobalModelState>;
    saveClearedNotices: Reducer<GlobalModelState>;
    changeproduct_id: Reducer<GlobalModelState>;
    saveCurMenuDetail: Reducer<GlobalModelState>;
    saveClientConfig: Reducer<GlobalModelState>;
    saveBasePath: Reducer<GlobalModelState>;
    changeNoPageStatus: Reducer<GlobalModelState>;
    saveBreadcrumbList: Reducer<GlobalModelState>;
  };
  subscriptions: { setup: Subscription };
}
// 登录进入时，product_id:null，默认为 1
// 外站跳转进入携带默认 product_id
const product_id = getStore('product_id') || getPageQuery().product_id;
const initState: GlobalModelState = {
  collapsed: false,
  notices: [],
  query: {},
  product_id: product_id ? Number(product_id) : 1,
  curMenu: {},
  clientConfig: undefined,
  basePath: [],
  breadcrumbList: [],
  noPage: '',
};

const GlobalModel: GlobalModelType = {
  namespace: 'global',

  state: initState,

  effects: {
    *fetchClientConfig({ payload }, { call, put }) {
      const res = yield call(getAppManageList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveClientConfig',
          payload: res.data,
        });
        const current_product_id = +localStorage.getItem('product_id');
        if (res.data.length > 0 && !map(res.data, 'product_id').includes(current_product_id)) {
          console.log('更新product_id');
          yield put({
            type: 'changeproduct_id',
            payload: res.data[0].product_id,
          });
        }
      }
    },
    *clearNotices({ payload }, { put, select }) {
      yield put({
        type: 'saveClearedNotices',
        payload,
      });
      const count: number = yield select(state => state.global.notices.length);
      const unreadCount: number = yield select(
        state => state.global.notices.filter(item => !item.read).length,
      );
      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: count,
          unreadCount,
        },
      });
    },
    *changeNoticeReadState({ payload }, { put, select }) {
      const notices: NoticeItem[] = yield select(state =>
        state.global.notices.map(item => {
          const notice = { ...item };
          if (notice.id === payload) {
            notice.read = true;
          }
          return notice;
        }),
      );

      yield put({
        type: 'saveNotices',
        payload: notices,
      });

      yield put({
        type: 'user/changeNotifyCount',
        payload: {
          totalCount: notices.length,
          unreadCount: notices.filter(item => !item.read).length,
        },
      });
    },
  },

  reducers: {
    saveCurMenuDetail(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        curMenu: payload.curMenu,
      };
    },
    saveQuery(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        query: payload,
      };
    },
    changeLayoutCollapsed(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        collapsed: payload,
      };
    },
    saveNotices(state = initState, { payload }): GlobalModelState {
      return {
        collapsed: false,
        ...state,
        notices: payload,
      };
    },
    saveClearedNotices(state = initState, { payload }): GlobalModelState {
      return {
        collapsed: false,
        ...state,
        notices: state.notices.filter((item): boolean => item.type !== payload),
      };
    },
    changeproduct_id(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        product_id: payload,
      };
    },
    saveClientConfig(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        clientConfig: payload,
      };
    },
    saveBasePath(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        basePath: payload,
      };
    },
    saveBreadcrumbList(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        breadcrumbList: payload,
      };
    },
    changeNoPageStatus(state = initState, { payload }): GlobalModelState {
      return {
        ...state,
        noPage: payload,
      };
    },
  },

  subscriptions: {
    setup({ history, dispatch }): void {
      history.listen((): void => {
        dispatch({ type: 'saveQuery', payload: getPageQuery() });
      });
    },
  },
};

export default GlobalModel;
