import { message, Modal } from 'antd';
import { AnyAction, Reducer } from 'redux';
import { EffectsCommandMap } from 'dva';
import {
  getAppManageList,
  addAppManage,
  removeAppManage,
  updateAppManage,
  getGroupList,
  deleteGroupList,
  updateGroupList,
  addGroupList,
  getUserList,
  addUserList,
  removeUserList,
  updateUserList,
  updateMetadata,
  addEventAttrList,
  getEventAttrList,
  updateEventAttrList,
  removeEventAttrList,
  getEventManageList,
  addEventManageList,
  updateEventManageList,
  removeEventManageList,
  getPrivateList,
  getActionList,
  getCustomFiledList,
  fetchVireventList,
  getUserMeasureList,
  copyEventManage,
  fetchGroupConfigList,
  getOrderAttrList,
  addOrderAttrList,
  updateOrderAttrList,
  removeOrderAttrList,
  getGoodsAttrList,
  addGoodsAttrList,
  updateGoodsAttrList,
  removeGoodsAttrList,
} from '@/services/metadata';
import { fetchEventMenuList } from '@/services/analyze';
import { EventMenuItem } from '@/pages/Analyze';
import { IGroupConfigItem } from './metadata.d';

export interface MetadataState {
  appManageList: [];
  groupManageList: {};
  userAttrList: {};
  eventAttrList: {};
  eventManageList: {};
  privateList: [];
  actionList: [];
  customFiledList: [];
  vireventList: {};
  eventMenuList: [];
  eventFilterConfig: {};
  userMeasureList: [];
  groupConfigList: IGroupConfigItem[];
  orderAttrList: {};
  goodsAttrList: {};
}
type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: {}) => T) => T },
) => void;

interface ModelType {
  namespace: string;
  state: MetadataState;
  effects: {
    getAppManageList: Effect;
    addAppManage: Effect;
    removeAppManage: Effect;
    updateAppManage: Effect;
    getGroupList: Effect;
    deleteGroupList: Effect;
    updateGroupList: Effect;
    addGroupList: Effect;
    getUserList: Effect;
    addUserList: Effect;
    removeUserList: Effect;
    updateUserList: Effect;
    updateMetadata: Effect;
    addEventAttrList: Effect;
    getEventAttrList: Effect;
    updateEventAttrList: Effect;
    removeEventAttrList: Effect;
    getEventManageList: Effect;
    addEventManageList: Effect;
    updateEventManageList: Effect;
    removeEventManageList: Effect;
    getPrivateList: Effect;
    getActionList: Effect;
    getCustomFiledList: Effect;
    fetchVireventList: Effect;
    fetchEventMenuList: Effect;
    getUserMeasureList: Effect;
    copyEventManage: Effect;
    fetchGroupConfigList: Effect;
    getOrderAttrList: Effect;
    addOrderAttrList: Effect;
    updateOrderAttrList: Effect;
    removeOrderAttrList: Effect;
    getGoodsAttrList: Effect;
    addGoodsAttrList: Effect;
    updateGoodsAttrList: Effect;
    removeGoodsAttrList: Effect;
  };
  reducers: {
    saveAppManageList: Reducer<MetadataState>;
    saveGroupManageList: Reducer<MetadataState>;
    saveUserAttrList: Reducer<MetadataState>;
    saveEventAttrList: Reducer<MetadataState>;
    saveEventManageList: Reducer<MetadataState>;
    savePrivateList: Reducer<MetadataState>;
    saveActionList: Reducer<MetadataState>;
    saveCustomFiledList: Reducer<MetadataState>;
    saveVireventList: Reducer<MetadataState>;
    saveEventMenuList: Reducer<MetadataState>;
    saveUserMeasureList: Reducer<MetadataState>;
    saveGroupConfigList: Reducer<MetadataState>;
    saveOrderAttrList: Reducer<MetadataState>;
    saveGoodsAttrList: Reducer<MetadataState>;
  };
}

const initState: MetadataState = {
  appManageList: [],
  groupManageList: {},
  userAttrList: {},
  eventAttrList: {},
  eventManageList: {},
  privateList: [],
  actionList: [],
  customFiledList: [],
  vireventList: {},
  eventMenuList: [],
  eventFilterConfig: {},
  userMeasureList: [],
  groupConfigList: [],
  orderAttrList: {},
  goodsAttrList: {}
};

const Model: ModelType = {
  namespace: 'metadata',
  state: initState,
  effects: {
    // app管理
    *getAppManageList({ payload }, { call, put }) {
      const res = yield call(getAppManageList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveAppManageList',
          payload: res.data,
        });
        yield put({
          type: 'global/saveClientConfig',
          payload: res.data,
        });
      }
    },
    *addAppManage({ payload, callback }, { call }) {
      const res = yield call(addAppManage, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *removeAppManage({ payload, callback }, { call }) {
      const res = yield call(removeAppManage, payload);
      if (res && +res.code === 0) {
        message.success('刪除成功！');
        callback();
      }
    },
    *updateAppManage({ payload, callback }, { call }) {
      const res = yield call(updateAppManage, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *updateMetadata({ callback, payload }, { call }) {
      const res = yield call(updateMetadata, payload);
      if (res && +res.code === 0) {
        message.success('刷新成功');
      } else {
        Modal.error({
          content: res ? res.msg : '刷新失败',
        });
      }
      callback();
    },
    // 事件分组管理
    *getGroupList({ payload }, { call, put }) {
      const res = yield call(getGroupList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveGroupManageList',
          payload: res.data,
        });
      }
    },
    *deleteGroupList({ payload, callback }, { call }) {
      const res = yield call(deleteGroupList, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
    *updateGroupList({ payload, callback }, { call }) {
      const res = yield call(updateGroupList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *addGroupList({ payload, callback }, { call }) {
      const res = yield call(addGroupList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    // 用户属性管理
    *getUserList({ payload }, { call, put }) {
      const res = yield call(getUserList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserAttrList',
          payload: res.data,
        });
      }
    },
    *addUserList({ payload, callback }, { call }) {
      const res = yield call(addUserList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *removeUserList({ payload, callback }, { call }) {
      const res = yield call(removeUserList, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
    *updateUserList({ payload, callback }, { call }) {
      const res = yield call(updateUserList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    // 事件属性管理
    *getEventAttrList({ payload }, { call, put }) {
      const res = yield call(getEventAttrList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventAttrList',
          payload: res.data,
        });
      }
    },
    *addEventAttrList({ payload, callback }, { call }) {
      const res = yield call(addEventAttrList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateEventAttrList({ payload, callback }, { call }) {
      const res = yield call(updateEventAttrList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *removeEventAttrList({ payload, callback }, { call }) {
      const res = yield call(removeEventAttrList, payload);
      if (res && +res.code === 0) {
        message.success('废止成功！');
        callback();
      }
    },
    // 订单属性管理
    *getOrderAttrList({ payload }, { call, put }) {
      const res = yield call(getOrderAttrList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveOrderAttrList',
          payload: res.data,
        });
      }
    },
    *addOrderAttrList({ payload, callback }, { call }) {
      const res = yield call(addOrderAttrList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateOrderAttrList({ payload, callback }, { call }) {
      const res = yield call(updateOrderAttrList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *removeOrderAttrList({ payload, callback }, { call }) {
      const res = yield call(removeOrderAttrList, payload);
      if (res && +res.code === 0) {
        message.success('废止成功！');
        callback();
      }
    },
    // 订单属性管理
    *getGoodsAttrList({ payload }, { call, put }) {
      const res = yield call(getGoodsAttrList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveGoodsAttrList',
          payload: res.data,
        });
      }
    },
    *addGoodsAttrList({ payload, callback }, { call }) {
      const res = yield call(addGoodsAttrList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateGoodsAttrList({ payload, callback }, { call }) {
      const res = yield call(updateGoodsAttrList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *removeGoodsAttrList({ payload, callback }, { call }) {
      const res = yield call(removeGoodsAttrList, payload);
      if (res && +res.code === 0) {
        message.success('废止成功！');
        callback();
      }
    },
    // 事件管理
    *getEventManageList({ payload }, { call, put }) {
      const res = yield call(getEventManageList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventManageList',
          payload: res.data,
        });
      }
    },
    *fetchVireventList({ payload }, { call, put }) {
      const res = yield call(fetchVireventList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveVireventList',
          payload: res.data,
        });
      }
    },
    *addEventManageList({ payload, callback }, { call }) {
      const res = yield call(addEventManageList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateEventManageList({ payload, callback }, { call }) {
      const res = yield call(updateEventManageList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *copyEventManage({ payload, callback }, { call }) {
      const res = yield call(copyEventManage, payload);
      if (res && +res.code === 0) {
        message.success('复制成功！');
        callback();
      }
    },
    *removeEventManageList({ payload, callback }, { call }) {
      const res = yield call(removeEventManageList, payload);
      if (res && +res.code === 0) {
        message.success('废止成功！');
        callback();
      }
    },
    *getPrivateList({ payload }, { call, put }) {
      const res = yield call(getPrivateList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'savePrivateList',
          payload: res.data,
        });
      }
    },
    *getActionList({ payload }, { call, put }) {
      const res = yield call(getActionList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveActionList',
          payload: res.data,
        });
      }
    },
    *getCustomFiledList(_, { call, put }) {
      const res = yield call(getCustomFiledList);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCustomFiledList',
          payload: res.data,
        });
      }
    },
    *fetchEventMenuList({ payload }, { call, put }) {
      const res = yield call(fetchEventMenuList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventMenuList',
          payload: res.data,
        });
      }
    },
    *getUserMeasureList({ payload }, { call, put }) {
      const res = yield call(getUserMeasureList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserMeasureList',
          payload: res.data,
        });
      }
    },
    *fetchGroupConfigList({ payload }, { call, put }) {
      const res = yield call(fetchGroupConfigList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveGroupConfigList',
          payload: res.data,
        });
      }
    },
  },
  reducers: {
    saveAppManageList(state = initState, { payload }) {
      return {
        ...state,
        appManageList: payload,
      };
    },
    saveGroupManageList(state = initState, { payload }) {
      return {
        ...state,
        groupManageList: payload,
      };
    },
    saveUserAttrList(state = initState, { payload }) {
      return {
        ...state,
        userAttrList: payload,
      };
    },
    saveEventAttrList(state = initState, { payload }) {
      return {
        ...state,
        eventAttrList: payload,
      };
    },
    saveEventManageList(state = initState, { payload }) {
      return {
        ...state,
        eventManageList: payload,
      };
    },
    savePrivateList(state = initState, { payload }) {
      return {
        ...state,
        privateList: payload,
      };
    },
    saveActionList(state = initState, { payload }) {
      return {
        ...state,
        actionList: payload,
      };
    },
    saveCustomFiledList(state = initState, { payload }) {
      return {
        ...state,
        customFiledList: payload,
      };
    },
    saveVireventList(state = initState, { payload }) {
      return {
        ...state,
        vireventList: payload,
      };
    },
    saveEventMenuList(state = initState, { payload }) {
      // 过滤虚拟事件
      const eventMenuList = payload.reduce((prev: EventMenuItem[], cur: EventMenuItem) => {
        if (Array.isArray(cur.events) && cur.events.some(i => +i.is_vir === 0)) {
          return [...prev, { ...cur, events: cur.events.filter(i => +i.is_vir === 0) }];
        }
        return prev;
      }, []);
      return {
        ...state,
        eventMenuList,
      };
    },
    saveUserMeasureList(state = initState, { payload }) {
      return {
        ...state,
        userMeasureList: payload,
      };
    },
    saveGroupConfigList(state = initState, { payload }) {
      return {
        ...state,
        groupConfigList: payload,
      };
    },
    saveOrderAttrList(state = initState, { payload }) {
      return {
        ...state,
        orderAttrList: payload,
      };
    },
    saveGoodsAttrList(state = initState, { payload }) {
      return {
        ...state,
        goodsAttrList: payload,
      };
    },
  },
};

export default Model;
