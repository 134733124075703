import { fetchSysUserList } from '@/services/system';
import { fetchCurrentUser, fetchUserAnalysisList, fetchUserInfo, fetchUserMenu } from '@/services/user';
import { Effect } from 'dva';
import { Reducer } from 'redux';
import { UserAnalysisList } from './user.d';
import { map } from 'lodash';
import { loginOutCb, setCookie, getCookie } from '@/utils/utils';
import { getWaterMark } from '@/utils/utils';

export interface CurrentUser {
  admin?: number;
  deptId?: number;
  deptName?: string;
  email?: string;
  eventIds?: [];
  eventTypeIds?: [];
  gmtCreate?: string;
  gmtModified?: string;
  loginWay?: string;
  menuIds?: number[];
  mobile?: null;
  name?: string;
  password?: string;
  roleIds?: [];
  status?: number;
  userId?: number;
  userIdCreate?: number;
  username?: string;
}

export interface UserMenuItem {
  attributes: {
    orderNum: number;
    type: number;
    url: string;
  };
  checked: boolean;
  children: UserMenuItem[];
  hasChildren: boolean;
  hasParent: boolean;
  id: string;
  parentId: string;
  text: string;
}

export interface UserModelState {
  currentUser: CurrentUser;
  userMenu: UserMenuItem;
  userAnalysisList: UserAnalysisList;
  allUser: any[];
}

const initState = {
  currentUser: {},
  userMenu: {} as UserMenuItem,
  userAnalysisList: {} as UserAnalysisList,
  allUser: [],
};
export interface UserModelType {
  namespace: string;
  state: UserModelState;
  effects: {
    fetchCurrentUser: Effect;
    fetchUserMenu: Effect;
    fetchUserAnalysisList: Effect;
    fetchAllUser: Effect;
  };
  reducers: {
    saveCurrentUser: Reducer<UserModelState>;
    saveUserMenu: Reducer<UserModelState>;
    saveUserAnalysisList: Reducer<UserModelState>;
    saveAllUser: Reducer<UserModelState>;
  };
}

const lookForAllChildren = (data = [], arr = []) => {
  for (let item of data) {
    arr.push(item);
    if (item.children && item.children.length) {
      lookForAllChildren(item.children, arr);
    }
  }
  return arr;
}

const UserModel: UserModelType = {
  namespace: 'user',

  state: initState,

  effects: {
    *fetchCurrentUser({ payload, callback }, { call, put }) {
      const res = yield call(fetchCurrentUser, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCurrentUser',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchUserMenu({ payload, callback }, { call, put }) {
      const res = yield call(fetchUserMenu, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserMenu',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      } else {
        loginOutCb();
      }
    },
    *fetchUserInfo({ payload, callback }, { call, put }) {
      const res = yield call(fetchUserInfo, payload);
      console.log('fetchUserInfo', res);
      if (res.code === 0 || res.code === 200) {
        const { userData, perms } = res.data;
        yield setCookie('perms', String(perms), 86400);
        yield setCookie('uid', userData.userId, 86400);
        if (getCookie('nauth-token')) {
          const name = getCookie('nauth-username');
          const userId = getCookie('nauth-userid');
          yield put({
            type: 'saveCurrentUser',
            payload: {
              name,
              userId,
              waterMark: getWaterMark(`${name} ${userId}`),
            },
          });
        } else {
          const res = yield call(fetchCurrentUser, { user_id: userData.userId, product_id: localStorage.getItem('product_id') });
          if (res && (+res.code === 200 || +res.code === 0)) {
            yield put({
              type: 'saveCurrentUser',
              payload: {
                ...res.data,
                waterMark: getWaterMark(res.data.email),
              },
            });
            typeof callback === 'function' && callback(res.data);
          }
        }
      }
    },

    *fetchUserAnalysisList({ payload }, { call, put }) {
      const res = yield call(fetchUserAnalysisList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserAnalysisList',
          payload: res.data,
        });
      }
    },
    *fetchAllUser({ payload }, { call, put }) {
      const res = yield call(fetchSysUserList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveAllUser',
          payload: res.data.rows,
        });
      }
    },
  },

  reducers: {
    saveCurrentUser(state = initState, { payload }) {
      return {
        ...state,
        currentUser: payload || {},
      };
    },
    saveUserMenu(state = initState, { payload }) {
      return {
        ...state,
        userMenu: payload,
      };
    },
    saveUserAnalysisList(state = initState, { payload }) {
      return {
        ...state,
        userAnalysisList: payload,
      };
    },
    saveAllUser(state = initState, { payload }) {
      return {
        ...state,
        allUser: payload,
      };
    },
  },
};

export default UserModel;
