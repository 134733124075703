import { Reducer } from 'redux';
import { Model } from 'dva';
import { message } from 'antd';
import { IEffect } from './connect.d';
import {
  addBookmark,
  updateBookmark,
  fetchBookmarkList,
  fetchSuperBookmarkList,
} from '@/services/bookmark';

export interface BookmarkState {
  bookmark_list: {};
  superBookmarkData: {};
}

export interface ModelType extends Model {
  namespace: string;
  state: BookmarkState;
  effects: {
    addBookmark: IEffect;
    updateBookmark: IEffect;
    fetchBookmarkList: IEffect;
    fetchSuperBookmarkList: IEffect;
  };
  reducers: {
    saveBookmarkList: Reducer<BookmarkState>;
    saveSuperBookmarkList: Reducer<BookmarkState>;
    clear: Reducer<BookmarkState>;
  };
}

const initState: BookmarkState = {
  bookmark_list: {},
  superBookmarkData: {},
};

const BookmarkModel: ModelType = {
  namespace: 'bookmark',

  state: initState,

  effects: {
    *addBookmark({ payload, callback }, { call }) {
      const res = yield call(addBookmark, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data.bookmark_id);
      }
    },
    *updateBookmark({ payload, callback }, { call }) {
      const res = yield call(updateBookmark, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback();
      }
    },
    *fetchBookmarkList({ payload }, { call, put }) {
      const res = yield call(fetchBookmarkList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveBookmarkList',
          payload: res.data,
        });
      }
    },
    *fetchSuperBookmarkList({ payload }, { call, put }) {
      const res = yield call(fetchSuperBookmarkList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveSuperBookmarkList',
          payload: res.data,
        });
      }
    },
  },

  reducers: {
    saveBookmarkList(state = initState, { payload }) {
      return {
        ...state,
        bookmark_list: payload,
      };
    },
    saveSuperBookmarkList(state = initState, { payload }) {
      return {
        ...state,
        superBookmarkData: payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default BookmarkModel;
