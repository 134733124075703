import { Reducer } from 'redux';
import { Model } from 'dva';
import { message } from 'antd';
import { fetchRoleList, addRole, updateRole, deleteRole } from '@/services/role';
import { IEffect } from './connect.d';

export interface RoleState {
  role_list: [];
}

export interface RoleType extends Model {
  namespace: string;
  state: RoleState;
  effects: {
    fetchRoleList: IEffect;
    addRole: IEffect;
    updateRole: IEffect;
    deleteRole: IEffect;
  };
  reducers: {
    saveRoleList: Reducer<RoleState>;
  };
}

const initState: RoleState = {
  role_list: [],
};

const RoleModel: RoleType = {
  namespace: 'role',

  state: initState,

  effects: {
    *fetchRoleList(_, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);

      const res = yield call(fetchRoleList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveRoleList',
          payload: res.data,
        });
      }
    },
    *addRole({ payload, callback }, { call }) {
      const res = yield call(addRole, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *updateRole({ payload, callback }, { call }) {
      const res = yield call(updateRole, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *deleteRole({ payload, callback }, { call }) {
      const res = yield call(deleteRole, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
  },

  reducers: {
    saveRoleList(state = initState, { payload }) {
      return {
        ...state,
        role_list: payload,
      };
    },
  },
};

export default RoleModel;
