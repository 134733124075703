import request from '@/utils/request';
import { Modal } from 'antd';
import { stringify } from 'qs';
import { Params } from './index.d';

const { CancelToken } = request;
let cancel: Function;

export async function fetchCurrentUser(params: Params): Promise<any> {
  return request.post('/api/sys/user/getUser', {
    data: params,
  });
}

/**
 *菜单管理-按用户获取菜单树
 *
 * @export
 * @returns {Promise<any>}
 */
export async function fetchUserMenu(params: Params): Promise<any> {
  return request(`/api/sys/menu/userMenu?${stringify(params)}`);
}

/**
 *用户分群--分群列表
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function fetchUserAnalysisList(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/list', {
    data: params,
  });
}

/**
 *用户分群--规则创建
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function addUserAnalysis(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/add', {
    data: params,
  });
}

/**
 *用户分群--人群预估
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function userAnalysisCrowdCount(params: Params): Promise<any> {
  const { pathname, ...rest } = params;
  return request
    .post('/api/user_analysis/crowd/count', {
      data: rest,
      cancelToken: new CancelToken(c => {
        cancel = c;
      }),
    })
    .then(res => {
      if (pathname === window.location.pathname && res && res.code === 0) {
        Modal.success({
          title: '预估人数',
          okText: '知道了',
          content: res.data.count_tip,
        });
      }
    });
}

export async function cancelAnalysisCrowdCount() {
  if (typeof cancel === 'function') {
    cancel();
  }
}

/**
 *用户分群--上传创建
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function uploadUserAnalysis(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/upload', {
    data: params,
    requestType: 'form',
  });
}

/**
 *用户分群--更新创建
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function updateUserAnalysis(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/update', {
    data: params,
  });
}

/**
 *用户分群-- 下载分群
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function downloadUserAnalysis(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/download', {
    data: params,
  });
}

/**
 *用户分群--删除分群
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function removeUserAnalysis(params: Params): Promise<any> {
  return request.post('/api/user_analysis/crowd/remove', {
    data: params,
  });
}

/**
 * 用户分群--属性分析
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function reportAttr(params: Params): Promise<any> {
  return request.post('/api/user_analysis/report', {
    data: params,
  });
}

export async function fetchContentFilterList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/content_filter_list', {
    data: params,
  });
}

export async function fetchContentGroupList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/content_group_list', {
    data: params,
  });
}

export async function fetchContentMeasureList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/content_measure_list', {
    data: params,
  });
}

export async function fetchContentReport(params: Params): Promise<any> {
  return request.post('/api/content_analysis/report', {
    data: params,
  });
}

export async function fetchPermissionMenu(params: Params): Promise<any> {
  return request(`/admin/main/userPermission?${stringify(params)}`);
}

export async function fetchUserInfo(params: Params): Promise<any> {
  return request(`/admin/main/userInfo?${stringify(params)}`);
}
