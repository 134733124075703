import request from '@/utils/request';
import { Params } from './index.d';

export async function addBookmark(params: Params): Promise<any> {
  return request.post('/api/bookmark/add', {
    data: params,
  });
}

export async function updateBookmark(params: Params): Promise<any> {
  return request.post('/api/bookmark/update', {
    data: params,
  });
}

export async function fetchBookmarkById(params: Params): Promise<any> {
  return request.post('/api/bookmark/id', {
    data: params,
  });
}

export async function fetchBookmarkList(params: Params): Promise<any> {
  return request.post('/api/bookmark/list', {
    data: params,
  });
}

/**
 * 超级书签
 * @param params
 */
export async function fetchSuperBookmarkList(params: Params): Promise<any> {
  return request.post('/api/bookmark/super_bookmark', {
    data: params,
  });
}
