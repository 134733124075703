import { getCookie } from '@/utils/utils';
import { stringify } from 'qs';
import request from '@/utils/request';
import { Params } from './index.d';

/**
 * 实验报告-列表
 */
export async function fetchReportList(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/list', {
    data: params,
  });
}

/**
 * 实验报告-详情
 */
export async function fetchReportDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/queryById', {
    data: params,
  });
}

/**
 * 实验报告-生成
 */
export async function addReport(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/add', {
    data: params,
  });
}

/**
 * 实验报告-删除
 */
export async function removeReport(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/remove', {
    data: params,
  });
}

/**
 * 实验报告-更新
 */
export async function updateReport(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/update', {
    data: params,
  });
}

/**
 * 实验报告-上传pdf
 */
export async function uploadPdf(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/uploadFlie', {
    data: params,
  });
}

/**
 * 实验报告-下载pdf
 */
export async function downloadPdf(params: Params): Promise<any> {
  return request(
    `/api/dashboard_report/downloadReport?${stringify({ ...params, token: getCookie('ida-token') })}`,
  );
}

/**
 * 实验报告-上传看板图表截图
 */
export async function uploadReportImg(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/upload_pic', {
    data: params,
  });
}

/**
 * 实验报告-上传报告结论图片
 */
export async function uploadReportConclusionImg(params: Params): Promise<any> {
  return request.post('/api/dashboard_report/image_host', {
    data: params,
  });
}
