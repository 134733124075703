import { Reducer, AnyAction } from 'redux';
import { Model } from 'dva';
import { message } from 'antd';
import {
  fetchConfigList,
  fetchInstanceFileList,
  fetchTaskList,
  fetchTaskInstanceList,
  addConfig,
  updateConfig,
  updateInstanceFile,
  removeInstanceFile,
  addTask,
  updateTask,
  removeTask,
  addTaskInstance,
  updateTaskInstance,
  removeTaskInstance,
} from '@/services/upload';
import { IEffect } from './connect.d';

export interface UploadState {
  config_list: {};
  instance_file_list: {};
  task_list: {};
  task_instance_list: {};
}

export interface ModelType extends Model {
  namespace: string;
  state: UploadState;
  effects: {
    fetchConfigList: IEffect;
    fetchInstanceFileList: IEffect;
    fetchTaskList: IEffect;
    fetchTaskInstanceList: IEffect;
    addConfig: IEffect;
    updateConfig: IEffect;
    updateInstanceFile: IEffect;
    removeInstanceFile: IEffect;
    addTask: IEffect;
    updateTask: IEffect;
    removeTask: IEffect;
    addTaskInstance: IEffect;
    updateTaskInstance: IEffect;
    removeTaskInstance: IEffect;
  };
  reducers: {
    saveConfigList: Reducer<UploadState>;
    saveInstanceFileList: Reducer<UploadState>;
    saveTaskList: Reducer<UploadState>;
    saveTaskInstanceList: Reducer<UploadState>;
    clear: Reducer<UploadState>;
  };
}

const initState: UploadState = {
  config_list: {},
  instance_file_list: {},
  task_list: {},
  task_instance_list: {},
};

function* updateEffects(request: Function, { payload, callback }: AnyAction, call: Function) {
  const res = yield call(request, payload);
  if (res && +res.code === 0) {
    message.success(res.msg);
    typeof callback === 'function' && callback(res.data);
  }
}

const UploadModel: ModelType = {
  namespace: 'upload',

  state: initState,

  effects: {
    *fetchConfigList({ payload, callback }, { call, put }) {
      const res = yield call(fetchConfigList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveConfigList',
          payload: res.data,
        });
      }
      typeof callback === 'function' && callback(res);
    },
    *fetchInstanceFileList({ payload, callback }, { call, put }) {
      const res = yield call(fetchInstanceFileList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveInstanceFileList',
          payload: res.data,
        });
      }
      typeof callback === 'function' && callback(res);
    },
    *fetchTaskList({ payload, callback }, { call, put }) {
      const res = yield call(fetchTaskList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTaskList',
          payload: res.data,
        });
      }
      typeof callback === 'function' && callback(res);
    },
    *fetchTaskInstanceList({ payload, callback }, { call, put }) {
      const res = yield call(fetchTaskInstanceList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTaskInstanceList',
          payload: res.data,
        });
      }
      typeof callback === 'function' && callback(res);
    },
    addConfig(action, { call }) {
      return updateEffects(addConfig, action, call);
    },
    updateConfig(action, { call }) {
      return updateEffects(updateConfig, action, call);
    },
    updateInstanceFile(action, { call }) {
      return updateEffects(updateInstanceFile, action, call);
    },
    removeInstanceFile(action, { call }) {
      return updateEffects(removeInstanceFile, action, call);
    },
    addTask(action, { call }) {
      return updateEffects(addTask, action, call);
    },
    updateTask(action, { call }) {
      return updateEffects(updateTask, action, call);
    },
    removeTask(action, { call }) {
      return updateEffects(removeTask, action, call);
    },
    addTaskInstance(action, { call }) {
      return updateEffects(addTaskInstance, action, call);
    },
    updateTaskInstance(action, { call }) {
      return updateEffects(updateTaskInstance, action, call);
    },
    removeTaskInstance(action, { call }) {
      return updateEffects(removeTaskInstance, action, call);
    },
  },

  reducers: {
    saveConfigList(state = initState, { payload }) {
      return {
        ...state,
        config_list: payload,
      };
    },
    saveInstanceFileList(state = initState, { payload }) {
      return {
        ...state,
        instance_file_list: payload,
      };
    },
    saveTaskList(state = initState, { payload }) {
      return {
        ...state,
        task_list: payload,
      };
    },
    saveTaskInstanceList(state = initState, { payload }) {
      return {
        ...state,
        task_instance_list: payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default UploadModel;
