import request from '@/utils/request';
import { Params } from './index.d';

/**
 * 获取书签列表
 */
export async function fetchBookmarkList(params: Params): Promise<any> {
  return request.post('/api/bookmark/list', { data: params });
}

/**
 * 删除书签
 */
export async function deleteBookmark(params: Params): Promise<any> {
  return request.post('/api/bookmark/delete', { data: params });
}

/**
 * 看板列表
 */
export async function fetchDashboardList(params: Params): Promise<any> {
  return request.post('/api/dashboard/list', {
    data: params,
  });
}

/**
 * 书签-看板关系更新
 */
export async function updateDashboard(params: Params): Promise<any> {
  return request.post('/api/bookmark/update_dashboard_relation', { data: params });
}

export async function getDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/get_show_result_v2', {
    data: params,
  });
}
/**
 * 看板-移除书签
 */
export async function deleteBookmarkFromDB(params: Params): Promise<any> {
  return request.post('/api/dashboard/delete_bookmark', {
    data: params,
  });
}

/**
 * 看板-看板分组列表
 */
export async function fetchDashboardGroupList(params: Params): Promise<any> {
  return request.post('/api/dashboard/groupList', {
    data: params,
  });
}

/**
 * 看板-新增看板
 */
export async function addDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/add', {
    data: params,
  });
}

/**
 * 看板-更新看板
 */
export async function updateDashboardData(params: Params): Promise<any> {
  return request.post('/api/dashboard/update', {
    data: params,
  });
}

/**
 * 看板-新增看板分组
 */
export async function addDashboardGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/group/add', {
    data: params,
  });
}

/**
 * 看板-删除看板分组
 */
export async function deleteDashboardGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/group/delete', {
    data: params,
  });
}

/**
 * 看板-更新看板分组
 */
export async function updateDashboardGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/group/update', {
    data: params,
  });
}

/**
 * 看板-添加书签到看板
 */
export async function addBookmarkToDashboard(params: Params): Promise<any> {
  return request.post('/api/bookmark/add_to_dashboard', {
    data: params,
  });
}

/**
 * 看板-移动看板
 */
export async function moveDashboardToGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_group', {
    data: params,
  });
}

/**
 * 看板-复制看板
 */
export async function copyDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/copy', {
    data: params,
  });
}

/**
 * 看板-定制看板
 */
export async function customizeDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/customize', {
    data: params,
  });
}

/**
 * 看板定制-临时查询
 */
export async function temporaryDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/temporary', {
    data: params,
  });
}

/**
 * 看板定制-临时批量查询
 */
export async function temporaryQueryByBookmarkId(params: Params): Promise<any> {
  return request.post('/api/dashboard/temp_query_by_bookmark_id', {
    data: params,
  });
}

/**
 * 看板定制-临时查询检验结果
 */
export async function temporaryStatResult(params: Params): Promise<any> {
  return request.post('/api/dashboard/get_temp_stat_result', {
    data: params,
  });
}

/**
 * 看板-定制看板
 */
export async function fetchDashboardCustomizeInfo(params: Params): Promise<any> {
  return request.post('/api/dashboard/showCustomizeSet', {
    data: params,
  });
}

/**
 * 基础指标-看板定制获取过滤条件
 */
export async function fetchDashboardCustomizeFilter(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/customize_fiter_list', { data: params });
}

/**
 * 基础指标-看板定制获取过滤条件
 */
export async function fetchDashboardCustomizeGroup(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/customize_group_list', { data: params });
}

/**
 * 看板授权-看板列表
 */
export async function fetchDashboardPowerlist(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/powerlist_v2', {
    data: params,
  });
}

/**
 * 看板授权-更改授权范围
 */
export async function updatePower(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_power', { data: params });
}

/**
 * 看板-更新书签排序
 */
export async function updateBookmarkOrder(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_bookmark_order_v2', {
    data: params,
  });
}

/**
 * 看板-删除看板
 */
export async function deleteDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/delete', {
    data: params,
  });
}

/**
 * 看板--获取结果数据（按书签id即席查询）
 */
export async function getResultByBookmark(params: Params): Promise<any> {
  return request.post('/api/dashboard/ad_hoc_analysis_by_bookmark_id', {
    data: params,
  });
}

/**
 * 自定义群组--群组列表
 */
export async function getCustomGroupList(params: Params): Promise<any> {
  return request.post('/api/sys/custom_group/list', {
    data: params,
  });
}

/**
 * 自定义群组--新增群组
 */
export async function addCustomGroupList(params: Params): Promise<any> {
  return request.post('/api/sys/custom_group/add', {
    data: params,
  });
}

/**
 * 自定义群组--更新群组
 */
export async function updateCustomGroupList(params: Params): Promise<any> {
  return request.post('/api/sys/custom_group/update', {
    data: params,
  });
}

/**
 * 自定义群组--删除群组
 */
export async function removeCustomGroupList(params: Params): Promise<any> {
  return request.post('/api/sys/custom_group/remove', {
    data: params,
  });
}

/**
 * 超级看板
 */
export async function fetchSuperDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/super_dashboard', {
    data: params,
  });
}

/**
 * 公共看板
 */
export async function commentDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/commentDashboard', {
    data: params,
  });
}

/**
 * AB实验列表
 */
export async function fetchABList(params: Params): Promise<any> {
  return request.post('/api/dashboard/ab_experiment_list', {
    data: params,
  });
}

/**
 * 看板--更新指标分类名称
 */
export async function updateIndicatorName(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_indicator_type_name', {
    data: params,
  });
}

/**
 * 看板--指标列表
 */
export async function fetchIndicatorList(params: Params): Promise<any> {
  return request.post('/api/dashboard/indicator_list', {
    data: params,
  });
}

/**
 * 看板--新增指标分类
 */
export async function addIndicatorList(params: Params): Promise<any> {
  return request.post('/api/dashboard/add_indicator_type', {
    data: params,
  });
}

/**
 * 看板--更新指标分类名称
 */
export async function updateIndicatorList(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_indicator_type_name', {
    data: params,
  });
}

/**
 * 看板--删除指标分类
 */
export async function removeIndicatorList(params: Params): Promise<any> {
  return request.post('/api/dashboard/remove_indicator_type', {
    data: params,
  });
}

/**
 * 看板--更新指标分类
 */
export async function updateIndicator(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_indicator', {
    data: params,
  });
}

/**
 * 看板--指标目录
 */
export async function getCatalogue(params: Params): Promise<any> {
  return request.post('/api/dashboard/catalogue', {
    data: params,
  });
}

/**
 * 看板授权--管理员列表
 */
export async function fetchDashboardPerAdminList(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/admin_list', {
    data: params,
  });
}

/**
 * 看板授权--可见人员列表
 */
export async function fetchDashboardPerGuestList(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/guest_list', {
    data: params,
  });
}

/**
 * 看板授权--新增管理员
 */
export async function addDashboardPerAdmin(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/add_admin', {
    data: params,
  });
}

/**
 * 看板授权--删除管理员
 */
export async function removeDashboardPerAdmin(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/remove_admin', {
    data: params,
  });
}

/**
 * 看板授权--新增可查看人员
 */
export async function addDashboardPerGuest(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/add_guest', {
    data: params,
  });
}

/**
 * 看板授权--删除可查看人员
 */
export async function removeDashboardPerGuest(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/remove_guest', {
    data: params,
  });
}

/**
 * 看板授权--可查看人员群组列表
 */
export async function fetchDashboardPerGuestGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/guest_custom_group_list', {
    data: params,
  });
}

/**
 * 看板授权--删除可查看人员群组列表
 */
export async function removeDashboardPerGuestGroup(params: Params): Promise<any> {
  return request.post('/api/dashboard/permission/remove_guest_custom_group', {
    data: params,
  });
}

/**
 * 看板--指标检验结果
 */
export async function getStatResult(params: Params): Promise<any> {
  return request.post('/api/dashboard/get_stat_result', {
    data: params,
  });
}

/**
 * 看板模板--指标详情
 */
export async function fetchDashboardIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/indicator_detail', {
    data: params,
  });
}

/**
 * 看板模板--指标详情
 */
export async function addOverviewIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/add_indicator', {
    data: params,
  });
}

/**
 * 看板模板--指标详情
 */
export async function updateOverviewIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/update_indicator_detail', {
    data: params,
  });
}

/**
 * 自定义群组--查询组内成员
 */
export async function fetchCustomGroupList(params: Params): Promise<any> {
  return request.post('/api/sys/custom_group/query_members', {
    data: params,
  });
}

/**
 * 隔离域列表
 */
export async function fetchIsolationList(params: Params): Promise<any> {
  return request.post('/api/dashboard/ab_isolation_list', {
    data: params,
  });
}

/**
 * 概览--根据概览id删除书签结果表数据
 */
export async function delBookmarkResultById(params: Params): Promise<any> {
  return request.post('/api/dashboard/del_bookmark_result_by_dashboardid', {
    data: params,
  });
}
