import { message } from 'antd';
import { Reducer } from 'redux';
import {
  fetchBookmarkList,
  deleteBookmark,
  fetchDashboardList,
  updateDashboard,
  getDashboard,
  deleteBookmarkFromDB,
  fetchDashboardGroupList,
  addDashboard,
  addDashboardGroup,
  deleteDashboardGroup,
  updateDashboardGroup,
  addBookmarkToDashboard,
  updateDashboardData,
  fetchDashboardPowerlist,
  updatePower,
  updateBookmarkOrder,
  deleteDashboard,
  getResultByBookmark,
  fetchDashboardCustomizeFilter,
  getCustomGroupList,
  addCustomGroupList,
  updateCustomGroupList,
  removeCustomGroupList,
  fetchSuperDashboard,
  fetchABList,
  updateIndicatorName,
  addIndicatorList,
  removeIndicatorList,
  fetchIndicatorList,
  updateIndicatorList,
  updateIndicator,
  getCatalogue,
  getStatResult,
  fetchDashboardIndicatorDetail,
  fetchDashboardCustomizeGroup,
  fetchIsolationList,
  temporaryQueryByBookmarkId,
  temporaryStatResult,
  delBookmarkResultById,
  commentDashboard,
} from '@/services/overview';
import { updateBookmark } from '@/services/bookmark';
import { IPanelDataProps, IDashboardItem } from '@/pages/Overview';
import { flatten } from '@/utils/utils';
import { Effect } from './connect.d';

const INIT_HOT_FETCH_COUNT = 4; // 初始请求书签的个数
export interface IHotFetchStatus {
  allBookmark: number[]; // 所有的书签数组
  awaitBookmark: number[]; // 等待请求的书签数组
  processFetchBookmark: number[]; // 正在请求的书签数组
  isExecuted: boolean; // 书签数组是否开始执行
}

export type IIsTempSearch = 0 | 1;
export interface OverviewModelState {
  bookmark_list: {};
  dashboard_list: [];
  dashboard: [];
  dashboard_group_list: {};
  powerData: {};
  bookmark_order: {}[];
  xlsxData: [];
  customGroupList: {};
  customizeFilterConfigCache: {};
  customizeGroupConfigCache: {};
  superDashboard: {};
  commentDashboard: {};
  ab_list: [];
  hotFetchStatus: IHotFetchStatus;
  indicatorList: [];
  catalogueList: [];
  stat: {
    stat_data: {
      by_type: string;
    };
  };
  isolationList: [];
  isTempSearch: IIsTempSearch;
  dashboard_last_modify_time: string;
  is_click_redo: boolean;
}

export interface ModelType {
  namespace: string;
  state: OverviewModelState;
  effects: {
    fetchBookmarkList: Effect;
    deleteBookmark: Effect;
    updateBookmark: Effect;
    fetchDashboardList: Effect;
    updateDashboard: Effect;
    getDashboard: Effect;
    deleteBookmarkFromDB: Effect;
    fetchDashboardGroupList: Effect;
    addDashboard: Effect;
    addDashboardGroup: Effect;
    deleteDashboardGroup: Effect;
    updateDashboardGroup: Effect;
    addBookmarkToDashboard: Effect;
    updateDashboardData: Effect;
    fetchDashboardPowerlist: Effect;
    updatePower: Effect;
    updateBookmarkOrder: Effect;
    deleteDashboard: Effect;
    getResultByBookmark: Effect;
    fetchDashboardCustomizeFilter: Effect;
    fetchDashboardCustomizeGroup: Effect;
    getCustomGroupList: Effect;
    addCustomGroupList: Effect;
    updateCustomGroupList: Effect;
    removeCustomGroupList: Effect;
    fetchSuperDashboard: Effect;
    fetchABList: Effect;
    updateIndicatorName: Effect;
    fetchIndicatorList: Effect;
    addIndicatorList: Effect;
    removeIndicatorList: Effect;
    updateIndicatorList: Effect;
    updateIndicator: Effect;
    getCatalogue: Effect;
    getStatResult: Effect;
    fetchDashboardIndicatorDetail: Effect;
    fetchIsolationList: Effect;
    delBookmarkResultById: Effect;
    commentDashboard: Effect;
  };
  reducers: {
    saveBookmarkList: Reducer<OverviewModelState>;
    saveDashboardList: Reducer<OverviewModelState>;
    saveDashboard: Reducer<OverviewModelState>;
    saveDashboardGroupList: Reducer<OverviewModelState>;
    savePowerList: Reducer<OverviewModelState>;
    saveBookmarkOrder: Reducer<OverviewModelState>;
    saveCustomizeFilterList: Reducer<OverviewModelState>;
    saveCustomizeGroupList: Reducer<OverviewModelState>;
    savePartialInfo: Reducer<OverviewModelState>;
    saveFetchedBookmark: Reducer<OverviewModelState>;
    saveXlsxData: Reducer<OverviewModelState>;
    updateXlsxData: Reducer<OverviewModelState>;
    saveCustomGroupList: Reducer<OverviewModelState>;
    saveSuperDashboard: Reducer<OverviewModelState>;
    saveIndicatorList: Reducer<OverviewModelState>;
    saveABList: Reducer<OverviewModelState>;
    changePathCleanUp: Reducer<OverviewModelState>;
    saveCatalogue: Reducer<OverviewModelState>;
    saveStatResult: Reducer<OverviewModelState>;
    saveIsolationList: Reducer<OverviewModelState>;
    changeSearchType: Reducer<OverviewModelState>;
    saveDashboardLastModifyTime: Reducer<OverviewModelState>;
    saveIsClickRedo: Reducer<OverviewModelState>;
    clear: Reducer<OverviewModelState>;
    saveCommentDashboard: Reducer<OverviewModelState>;
  };
}
const initState: OverviewModelState = {
  bookmark_list: {},
  dashboard_list: [],
  dashboard: [],
  dashboard_group_list: {},
  powerData: [],
  bookmark_order: [],
  customGroupList: {},
  xlsxData: [],
  customizeFilterConfigCache: {},
  customizeGroupConfigCache: {},
  superDashboard: {},
  commentDashboard: {},
  ab_list: [],
  hotFetchStatus: {
    allBookmark: [],
    awaitBookmark: [],
    processFetchBookmark: [],
    isExecuted: false,
  },
  indicatorList: [],
  catalogueList: [],
  stat: {
    stat_data: {},
  },
  isolationList: [],
  isTempSearch: 0,
  dashboard_last_modify_time: '',
  is_click_redo: false,
};

const Model: ModelType = {
  namespace: 'overview',
  state: initState,
  effects: {
    *fetchBookmarkList({ payload }, { call, put }) {
      const res = yield call(fetchBookmarkList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveBookmarkList',
          payload: res.data,
        });
      }
    },
    *deleteBookmark({ payload }, { call }) {
      const res = yield call(deleteBookmark, payload);
      if (res && +res.code !== 0) {
        throw new Error(res.msg);
      }
    },
    *updateBookmark({ payload, callback }, { call }) {
      const res = yield call(updateBookmark, payload);
      if (res && +res.code === 0) {
        callback();
      }
    },
    *fetchDashboardList(_, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchDashboardList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveDashboardList',
          payload: res.data,
        });
      }
    },
    *updateDashboard({ payload }, { call }) {
      const res = yield call(updateDashboard, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
      }
    },
    *getDashboard({ payload, callback, errorCb }, { call, put }) {
      const res = yield call(getDashboard, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveBookmarkOrder',
          payload: res.data.map((d: IDashboardItem) => ({
            indicator_type_id: d.indicator_type_id,
            indicator_ids: d.list.map(l => l.bookmark_id),
          })),
        });

        yield put({
          type: 'saveDashboard',
          payload: res.data,
        });
        callback && callback(res.data);

        const listArr = flatten(res.data.map((r: IDashboardItem) => r.list));

        yield put({
          type: 'saveXlsxData',
          payload: listArr,
        });

        if (res.data.length) {
          yield put({
            type: 'saveDashboardLastModifyTime',
            payload: res.data[0].last_modify_time,
          });
        }

        // 过滤出看板书签没有数据的bookmark_id, 将前INIT_HOT_FETCH_COUNT个添加到正在搜索的数组中
        // 其余的添加到等待的数组中
        yield put({
          type: 'savePartialInfo',
          payload: listArr.reduce(
            (prev: IHotFetchStatus, cur: IPanelDataProps) => {
              const { allBookmark, awaitBookmark, processFetchBookmark } = prev;
              const { chart: { series = [] } = {}, bookmark_id } = cur;
              if (!Array.isArray(series) || !series.length) {
                allBookmark.push(bookmark_id);
                if (processFetchBookmark.length < INIT_HOT_FETCH_COUNT) {
                  processFetchBookmark.push(bookmark_id);
                } else {
                  awaitBookmark.push(bookmark_id);
                }
              }
              return prev;
            },
            {
              allBookmark: [],
              awaitBookmark: [],
              processFetchBookmark: [],
              isExecuted: true,
            } as IHotFetchStatus,
          ),
        });
      } else {
        typeof errorCb === 'function' && errorCb();
      }
    },
    *deleteBookmarkFromDB({ payload, callback }, { call }) {
      const res = yield call(deleteBookmarkFromDB, payload);
      if (res && +res.code === 0) {
        callback();
      }
    },
    *fetchDashboardGroupList(_, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchDashboardGroupList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveDashboardGroupList',
          payload: res.data,
        });
      }
    },
    *addDashboard({ payload, callback }, { call }) {
      const res = yield call(addDashboard, payload);
      if (res && +res.code === 0) {
        message.success('添加看板成功');
        callback();
      }
    },
    *addDashboardGroup({ payload, callback }, { call }) {
      const res = yield call(addDashboardGroup, payload);
      if (res && +res.code === 0) {
        message.success('添加分组成功');
        callback();
      }
    },
    *deleteDashboardGroup({ payload, callback }, { call }) {
      const res = yield call(deleteDashboardGroup, payload);
      if (res && +res.code === 0) {
        message.success('删除成功');
        callback();
      }
    },
    *updateDashboardGroup({ payload, callback }, { call }) {
      const res = yield call(updateDashboardGroup, payload);
      if (res && +res.code === 0) {
        message.success('修改成功');
        callback();
      }
    },
    *addBookmarkToDashboard({ payload, callback }, { call }) {
      const res = yield call(addBookmarkToDashboard, payload);
      if (res && +res.code === 0) {
        message.success('添加成功');
        callback();
      }
    },
    *updateDashboardData({ payload, callback }, { call }) {
      const res = yield call(updateDashboardData, payload);
      if (res && +res.code === 0) {
        message.success('更新成功');
        callback();
      }
    },
    *fetchDashboardPowerlist({ payload, callback }, { call, put }) {
      const res = yield call(fetchDashboardPowerlist, payload);
      if (res && +res.code === 0) {
        callback && callback(res.data);
        yield put({
          type: 'savePowerList',
          payload: res.data,
        });
      }
    },
    *updatePower({ payload, callback }, { call }) {
      const res = yield call(updatePower, payload);
      if (res && +res.code === 0) {
        callback();
      }
    },
    *updateBookmarkOrder({ payload, callback }, { call }) {
      const res = yield call(updateBookmarkOrder, payload);
      if (res && +res.code === 0) {
        message.success('更新成功');
        callback();
      }
    },
    *deleteDashboard({ payload, callback }, { call }) {
      const res = yield call(deleteDashboard, payload);
      if (res && +res.code === 0) {
        message.success('删除成功');
        callback();
      }
    },
    *getResultByBookmark({ payload, callback, errorCb }, { call, select, put }) {
      const isTempSearch = yield select(state => state.overview.isTempSearch);
      const isClickRedo = yield select(state => state.overview.is_click_redo);

      const res = yield call(
        isTempSearch === 1 ? temporaryQueryByBookmarkId : getResultByBookmark,
        isTempSearch === 1
          ? payload
          : isClickRedo
          ? { ...payload, skip_cache: 1 }
          : { ...payload, skip_cache: 0 },
      );

      if (res && +res.code === 0) {
        const modifyTime = yield select(state => state.overview.dashboard_last_modify_time);
        const date = new Date(modifyTime);
        const newDate = res.data.modify_time ? new Date(res.data.modify_time) : null;
        if (newDate && newDate < date) {
          yield put({
            type: 'saveDashboardLastModifyTime',
            payload: res.data.modify_time,
          });
        }
        typeof callback === 'function' && callback(res.data);
      } else {
        typeof errorCb === 'function' && errorCb();
      }
    },
    *fetchDashboardCustomizeFilter({ payload }, { call, put }) {
      const res = yield call(fetchDashboardCustomizeFilter, payload);
      const { product_id } = payload;
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCustomizeFilterList',
          payload: { key: product_id, data: res.data },
        });
      }
    },
    *fetchDashboardCustomizeGroup({ payload }, { call, put }) {
      const res = yield call(fetchDashboardCustomizeGroup, payload);
      const { product_id } = payload;
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCustomizeGroupList',
          payload: { key: product_id, data: res.data },
        });
      }
    },
    *getCustomGroupList({ payload }, { call, put }) {
      const res = yield call(getCustomGroupList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCustomGroupList',
          payload: res.data,
        });
      }
    },
    *addCustomGroupList({ payload, callback }, { call }) {
      const res = yield call(addCustomGroupList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功');
        callback();
      }
    },
    *updateCustomGroupList({ payload, callback }, { call }) {
      const res = yield call(updateCustomGroupList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功');
        callback();
      }
    },
    *removeCustomGroupList({ payload, callback }, { call }) {
      const res = yield call(removeCustomGroupList, payload);
      if (res && +res.code === 0) {
        message.success('删除成功');
        callback();
      }
    },
    *fetchSuperDashboard({ payload }, { call, put }) {
      const res = yield call(fetchSuperDashboard, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveSuperDashboard',
          payload: res.data,
        });
      }
    },
    *commentDashboard({ payload }, { call, put,select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(commentDashboard, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCommentDashboard',
          payload: res.data,
        });
      }
      // const product_id = yield select(state => state.global.product_id);
      // const res = yield call(commentDashboard, { product_id });
      // // const res = yield call(commentDashboard, payload);
      // if (res && +res.code === 0) {
      //   yield put({
      //     type: 'saveCommentDashboard',
      //     payload: res.data,
      //   });
      // }
    },
    *fetchABList({ payload }, { call, put }) {
      const res = yield call(fetchABList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveABList',
          payload: res.data,
        });
      }
    },
    *updateIndicatorName({ payload, callback }, { call }) {
      const res = yield call(updateIndicatorName, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *fetchIndicatorList({ payload, callback }, { call, put }) {
      const res = yield call(fetchIndicatorList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveIndicatorList',
          payload: res.data,
        });
        // 保存到保存指标弹窗指标列表
        yield put({
          type: 'modal/saveTargetList',
          payload: res.data,
        });
        callback && callback(res.data);
      }
    },
    *addIndicatorList({ payload, callback }, { call }) {
      const res = yield call(addIndicatorList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateIndicatorList({ payload, callback }, { call }) {
      const res = yield call(updateIndicatorList, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *removeIndicatorList({ payload, callback }, { call }) {
      const res = yield call(removeIndicatorList, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
    *updateIndicator({ payload, callback }, { call }) {
      const res = yield call(updateIndicator, payload);
      if (res && +res.code === 0) {
        message.success('更新成功！');
        callback();
      }
    },
    *getCatalogue({ payload }, { call, put }) {
      const res = yield call(getCatalogue, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCatalogue',
          payload: res.data,
        });
      }
    },
    *getStatResult({ payload }, { call, put, select }) {
      const isTempSearch = yield select(state => state.overview.isTempSearch);
      const res = yield call(isTempSearch === 1 ? temporaryStatResult : getStatResult, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveStatResult',
          payload: res.data,
        });
      }
    },
    *fetchDashboardIndicatorDetail({ payload }, { call, put }) {
      const res = yield call(fetchDashboardIndicatorDetail, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'analyze/saveBookMark',
          payload: res.data,
        });
      }
    },
    *fetchIsolationList({ payload }, { call, put }) {
      const res = yield call(fetchIsolationList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveIsolationList',
          payload: res.data,
        });
      }
    },
    *delBookmarkResultById({ payload, callback }, { call }) {
      const res = yield call(delBookmarkResultById, payload);
      if (res && +res.code === 0) {
        callback();
      }
    },
  },
  reducers: {
    saveBookmarkList(state = initState, { payload }) {
      return {
        ...state,
        bookmark_list: payload,
      };
    },
    saveDashboardList(state = initState, { payload }) {
      return {
        ...state,
        dashboard_list: payload,
      };
    },
    saveDashboard(state = initState, { payload }) {
      return {
        ...state,
        dashboard: payload,
      };
    },
    saveDashboardGroupList(state = initState, { payload }) {
      return {
        ...state,
        dashboard_group_list: payload,
      };
    },
    savePowerList(state = initState, { payload }) {
      return {
        ...state,
        powerData: payload,
      };
    },
    saveBookmarkOrder(state = initState, { payload }) {
      return {
        ...state,
        bookmark_order: payload,
      };
    },
    saveCustomizeFilterList(state = initState, { payload }) {
      const { customizeFilterConfigCache } = state;
      const { key, data } = payload;
      return {
        ...state,
        customizeFilterConfigCache: { ...customizeFilterConfigCache, [key]: data },
      };
    },
    saveCustomizeGroupList(state = initState, { payload }) {
      const { customizeGroupConfigCache } = state;
      const { key, data } = payload;
      return {
        ...state,
        customizeGroupConfigCache: { ...customizeGroupConfigCache, [key]: data },
      };
    },
    savePartialInfo(state = initState, { payload }) {
      return {
        ...state,
        hotFetchStatus: payload,
      };
    },
    saveFetchedBookmark(state = initState, { payload }) {
      const {
        hotFetchStatus: { allBookmark, awaitBookmark, processFetchBookmark, isExecuted },
      } = state;
      const { bookmark_id } = payload;
      // 数据请求结束后将等待请求数组中的第一项放入正在请求的数组中, 并删除等待请求数组中的第一项
      const newHotFetchStatus = {
        allBookmark,
        awaitBookmark: awaitBookmark.slice(1),
        processFetchBookmark: processFetchBookmark.filter(i => i !== bookmark_id),
        isExecuted,
      } as IHotFetchStatus;
      if (awaitBookmark.length) {
        newHotFetchStatus.processFetchBookmark = newHotFetchStatus.processFetchBookmark.concat(
          awaitBookmark[0],
        );
      }
      return {
        ...state,
        hotFetchStatus: newHotFetchStatus,
      };
    },
    saveXlsxData(state = initState, { payload }) {
      return {
        ...state,
        xlsxData: payload,
      };
    },
    updateXlsxData(state = initState, { payload }) {
      const { bookmark_id, data } = payload;
      const newData = state.xlsxData.map((item: any) =>
        item.bookmark_id === bookmark_id ? data : item,
      );
      return {
        ...state,
        xlsxData: newData,
      };
    },
    saveCustomGroupList(state = initState, { payload }) {
      return {
        ...state,
        customGroupList: payload,
      };
    },
    saveSuperDashboard(state = initState, { payload }) {
      return {
        ...state,
        superDashboard: payload,
      };
    },
    saveCommentDashboard(state = initState, { payload }) {
      return {
        ...state,
        commentDashboard: payload,
      };
    },
    saveABList(state = initState, { payload }) {
      return {
        ...state,
        ab_list: payload,
      };
    },
    changePathCleanUp(state = initState) {
      return {
        ...state,
        dashboard: [],
        bookmark_order: [],
        xlsxData: [],
        stat: {
          stat_data: {},
        },
        hotFetchStatus: {
          allBookmark: [],
          awaitBookmark: [],
          processFetchBookmark: [],
          isExecuted: false,
        },
        isTempSearch: 0,
        is_click_redo: false,
      };
    },
    saveIndicatorList(state = initState, { payload }) {
      return {
        ...state,
        indicatorList: payload,
      };
    },
    saveCatalogue(state = initState, { payload }) {
      return {
        ...state,
        catalogueList: payload,
      };
    },
    saveStatResult(state = initState, { payload }) {
      return {
        ...state,
        stat: payload,
      };
    },
    saveIsolationList(state = initState, { payload }) {
      return {
        ...state,
        isolationList: payload,
      };
    },
    changeSearchType(state = initState, { payload }) {
      return {
        ...state,
        isTempSearch: payload,
      };
    },
    saveDashboardLastModifyTime(state = initState, { payload }) {
      return {
        ...state,
        dashboard_last_modify_time: payload,
      };
    },
    saveIsClickRedo(state = initState, { payload }) {
      return {
        ...state,
        is_click_redo: payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
