import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from '/data0/web/dida.98du.com/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/login',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__Login__Login" */ '../Login/Login'),
          LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
            .default,
        })
      : require('../Login/Login').default,
    exact: true,
  },
  {
    path: '/login/by_oa',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "p__Login__LoginByOa" */ '../Login/LoginByOa'),
          LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
            .default,
        })
      : require('../Login/LoginByOa').default,
    exact: true,
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    routes: [
      {
        path: '/overview',
        name: '看板',
        icon: 'dashboard',
        routes: [
          {
            name: '事件分析',
            path: '/overview/:id/indicator/event_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__EventAnalyze" */ '../Analyze/EventAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/EventAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '漏斗分析',
            path: '/overview/:id/indicator/funnel_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__FunnelAnalyze" */ '../Analyze/FunnelAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/FunnelAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '留存分析',
            path: '/overview/:id/indicator/keep_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__KeepAnalyze" */ '../Analyze/KeepAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/KeepAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '分布分析',
            path: '/overview/:id/indicator/distributed_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__DistributedAnalyze" */ '../Analyze/DistributedAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/DistributedAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '书签管理',
            path: '/overview/:id/indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Indicator" */ '../Overview/Indicator'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Indicator').default,
            exact: true,
          },
          {
            name: '权限管理',
            path: '/overview/:id/auth_list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Auth" */ '../Overview/Auth'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Auth').default,
            exact: true,
          },
          {
            name: '实验报告详情',
            path: '/overview/:id/report/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__ReportDetail" */ '../Overview/ReportDetail'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/ReportDetail').default,
            exact: true,
          },
          {
            name: '实验报告',
            path: '/overview/:id/report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Report" */ '../Overview/Report'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Report').default,
            exact: true,
          },
          {
            name: '看板',
            path: '/overview/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Default" */ '../Overview/Default'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Default').default,
            exact: true,
          },
        ],
      },
      {
        path: '/super_overview',
        name: '超级看板',
        routes: [
          {
            name: '事件分析',
            path: '/super_overview/:id/indicator/event_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__EventAnalyze" */ '../Analyze/EventAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/EventAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '漏斗分析',
            path: '/super_overview/:id/indicator/funnel_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__FunnelAnalyze" */ '../Analyze/FunnelAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/FunnelAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '留存分析',
            path: '/super_overview/:id/indicator/keep_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__KeepAnalyze" */ '../Analyze/KeepAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/KeepAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '分布分析',
            path: '/super_overview/:id/indicator/distributed_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__DistributedAnalyze" */ '../Analyze/DistributedAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/DistributedAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '书签管理',
            path: '/super_overview/:id/indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Indicator" */ '../Overview/Indicator'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Indicator').default,
            exact: true,
          },
          {
            name: '权限管理',
            path: '/super_overview/:id/auth_list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Auth" */ '../Overview/Auth'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Auth').default,
            exact: true,
          },
          {
            name: '实验报告详情',
            path: '/super_overview/:id/report/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__ReportDetail" */ '../Overview/ReportDetail'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/ReportDetail').default,
            exact: true,
          },
          {
            name: '实验报告',
            path: '/super_overview/:id/report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Report" */ '../Overview/Report'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Report').default,
            exact: true,
          },
          {
            name: '看板',
            path: '/super_overview/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Default" */ '../Overview/Default'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Default').default,
            exact: true,
          },
        ],
      },
      {
        path: '/public_overview',
        name: '公共看板',
        routes: [
          {
            name: '事件分析',
            path: '/public_overview/:id/indicator/event_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__EventAnalyze" */ '../Analyze/EventAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/EventAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '漏斗分析',
            path: '/public_overview/:id/indicator/funnel_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__FunnelAnalyze" */ '../Analyze/FunnelAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/FunnelAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '留存分析',
            path: '/public_overview/:id/indicator/keep_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__KeepAnalyze" */ '../Analyze/KeepAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/KeepAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '分布分析',
            path: '/public_overview/:id/indicator/distributed_indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__DistributedAnalyze" */ '../Analyze/DistributedAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/DistributedAnalyze').default,
            isIndicator: true,
            isOverviewIndicator: true,
            exact: true,
          },
          {
            name: '书签管理',
            path: '/public_overview/:id/indicator',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Indicator" */ '../Overview/Indicator'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Indicator').default,
            exact: true,
          },
          {
            name: '权限管理',
            path: '/public_overview/:id/auth_list',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Auth" */ '../Overview/Auth'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Auth').default,
            exact: true,
          },
          {
            name: '实验报告详情',
            path: '/public_overview/:id/report/detail',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__ReportDetail" */ '../Overview/ReportDetail'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/ReportDetail').default,
            exact: true,
          },
          {
            name: '实验报告',
            path: '/public_overview/:id/report',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Report" */ '../Overview/Report'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Report').default,
            exact: true,
          },
          {
            name: '看板',
            path: '/public_overview/:id',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Default" */ '../Overview/Default'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Default').default,
            exact: true,
          },
        ],
      },
      {
        path: '/analyze',
        name: '自助分析',
        icon: 'form',
        routes: [
          {
            path: '/analyze/event_analyze',
            name: '事件分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__EventAnalyze" */ '../Analyze/EventAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/EventAnalyze').default,
            exact: true,
          },
          {
            path: '/analyze/keep_analyze',
            name: '留存分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__KeepAnalyze" */ '../Analyze/KeepAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/KeepAnalyze').default,
            exact: true,
          },
          {
            path: '/analyze/funnel_analyze',
            name: '漏斗分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__FunnelAnalyze" */ '../Analyze/FunnelAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/FunnelAnalyze').default,
            exact: true,
          },
          {
            path: '/analyze/distributed_analyze',
            name: '分布分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__DistributedAnalyze" */ '../Analyze/DistributedAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/DistributedAnalyze').default,
            exact: true,
          },
        ],
      },
      {
        path: '/my_config',
        name: '我的设置',
        routes: [
          {
            path: '/my_config/authorization',
            name: '看板授权',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Authorization" */ '../Overview/Authorization'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Authorization').default,
            exact: true,
          },
          {
            name: '权限管理',
            path: '/my_config/authorization/edit',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Auth" */ '../Overview/Auth'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Auth').default,
            exact: true,
          },
          {
            path: '/my_config/bookmark',
            name: '书签管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Overview__Bookmark" */ '../Overview/Bookmark'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Overview/Bookmark').default,
            exact: true,
          },
        ],
      },
      {
        path: '/super/bookmark',
        name: '超级书签',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Overview__SuperBookmark" */ '../Overview/SuperBookmark'),
              LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                .default,
            })
          : require('../Overview/SuperBookmark').default,
        exact: true,
      },
      {
        path: '/user',
        name: '自助分析',
        icon: 'form',
        routes: [
          {
            path: '/user/crowd',
            name: '用户分群',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__User__UserCrowd" */ '../User/UserCrowd'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../User/UserCrowd').default,
            exact: true,
          },
          {
            path: '/user/attribute',
            name: '属性分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__User__AttrAnalyze" */ '../User/AttrAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../User/AttrAnalyze').default,
            exact: true,
          },
          {
            path: '/user/entity',
            name: '实体分析',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__User__EntityAnalyze" */ '../User/EntityAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../User/EntityAnalyze').default,
            exact: true,
          },
        ],
      },
      {
        path: '/system',
        name: '系统管理',
        icon: 'setting',
        routes: [
          {
            path: '/system/user_list',
            name: '用户列表',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System__UserList" */ '../System/UserList'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../System/UserList').default,
            exact: true,
          },
          {
            path: '/system/task',
            name: '任务管理',
            routes: [
              {
                path: '/system/task/list',
                name: '任务列表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TaskList'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TaskList').default,
                exact: true,
              },
              {
                path: '/system/task/instances',
                name: '任务实例列表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/TaskInstances'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/TaskInstances').default,
                exact: true,
              },
            ],
          },
          {
            path: '/system/usertask',
            name: '用户分群',
            routes: [
              {
                path: '/system/usertask/list',
                name: '人群列表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/UserTaskList'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/UserTaskList').default,
                exact: true,
              },
              {
                path: '/system/usertask/instances',
                name: '人群实例列表',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/UserTaskInstances'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/UserTaskInstances').default,
                exact: true,
              },
            ],
          },
          {
            path: '/system/role',
            name: '角色管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System__RoleList" */ '../System/RoleList'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../System/RoleList').default,
            exact: true,
          },
          {
            path: '/system/menu',
            name: '菜单管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System__MenuList" */ '../System/MenuList'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../System/MenuList').default,
            exact: true,
          },
          {
            path: '/system/log',
            name: '日志明细',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__System__Log" */ '../System/Log'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../System/Log').default,
            exact: true,
          },
          {
            path: '/system/report',
            name: '系统报表',
            routes: [
              {
                path: '/system/report/user',
                name: '系统使用情况',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Report/UseCase'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Report/UseCase').default,
                exact: true,
              },
              {
                path: '/system/report/task',
                name: '看板运行情况',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Report/RunCase'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Report/RunCase').default,
                exact: true,
              },
              {
                path: '/system/report/board_use',
                name: '看板运行情况',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Report/BoardUse'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Report/BoardUse').default,
                exact: true,
              },
              {
                path: '/system/report/query_uid',
                name: '分析效率-按用户',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Report/UserEA'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Report/UserEA').default,
                exact: true,
              },
              {
                path: '/system/report/query_type',
                name: '分析效率-按类型',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../System/Report/TypeEA'),
                      LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../System/Report/TypeEA').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        path: '/metadata',
        name: '元数据管理',
        icon: 'file-text',
        routes: [
          {
            path: '/metadata/group_manage',
            name: '配置组管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__GroupManage" */ '../Metadata/GroupManage'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/GroupManage').default,
            exact: true,
          },
          {
            path: '/metadata/app_manage',
            name: 'App管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__AppManage" */ '../Metadata/AppManage'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/AppManage').default,
            exact: true,
          },
          {
            path: '/metadata/event_group',
            name: '事件分组管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__EventGroup" */ '../Metadata/EventGroup'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/EventGroup').default,
            exact: true,
          },
          {
            path: '/metadata/user_attr',
            name: '用户属性',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__UserAttr" */ '../Metadata/UserAttr'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/UserAttr').default,
            exact: true,
          },
          {
            path: '/metadata/event_attr',
            name: '事件属性',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__EventAttr" */ '../Metadata/EventAttr'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/EventAttr').default,
            exact: true,
          },
          {
            path: '/metadata/event_manage',
            name: '事件管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__EventManage" */ '../Metadata/EventManage'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/EventManage').default,
            exact: true,
          },
          {
            path: '/metadata/order_attr',
            name: '订单属性',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__OrderAttr" */ '../Metadata/OrderAttr'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/OrderAttr').default,
            exact: true,
          },
          {
            path: '/metadata/goods_attr',
            name: '商品属性',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__GoodsAttr" */ '../Metadata/GoodsAttr'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/GoodsAttr').default,
            exact: true,
          },
          {
            path: '/metadata/content_attr',
            name: '内容属性',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__ContentAttr" */ '../Metadata/ContentAttr'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/ContentAttr').default,
            exact: true,
          },
          {
            path: '/metadata/virevent_setting',
            name: '虚拟事件配置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Metadata__VirEventManage" */ '../Metadata/VirEventManage'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Metadata/VirEventManage').default,
            exact: true,
          },
        ],
      },
      {
        path: '/upload_task',
        name: '上传任务配置管理',
        routes: [
          {
            path: '/upload_task/config',
            name: '上传配置项',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Upload__TaskConfig" */ '../Upload/TaskConfig'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Upload/TaskConfig').default,
            exact: true,
          },
          {
            path: '/upload_task/instance_files',
            name: '上传文件实例',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Upload__InstanceFiles" */ '../Upload/InstanceFiles'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Upload/InstanceFiles').default,
            exact: true,
          },
          {
            path: '/upload_task/task',
            name: '上传任务',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Upload__Task" */ '../Upload/Task'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Upload/Task').default,
            exact: true,
          },
          {
            path: '/upload_task/instance_task',
            name: '上传任务实例',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Upload__TaskInstances" */ '../Upload/TaskInstances'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Upload/TaskInstances').default,
            exact: true,
          },
          {
            path: '/upload_task/monitor',
            name: '上传监控',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Upload__Monitor" */ '../Upload/Monitor'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Upload/Monitor').default,
            exact: true,
          },
        ],
      },
      {
        path: '/overview_model',
        name: '看板模版',
        routes: [
          {
            path: '/overview_model/manage',
            name: '模版管理',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Modal__Manage" */ '../Modal/Manage'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Modal/Manage').default,
            exact: true,
          },
          {
            path: '/overview_model/indicator',
            name: '看板模版设置',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Modal__Indicator" */ '../Modal/Indicator'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Modal/Indicator').default,
            exact: true,
          },
          {
            path: '/overview_model/event_indicator',
            name: '看板模版-事件指标',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__EventAnalyze" */ '../Analyze/EventAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/EventAnalyze').default,
            isIndicator: true,
            exact: true,
          },
          {
            path: '/overview_model/funnel_indicator',
            name: '看板模版-漏斗指标',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__FunnelAnalyze" */ '../Analyze/FunnelAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/FunnelAnalyze').default,
            isIndicator: true,
            exact: true,
          },
          {
            path: '/overview_model/keep_indicator',
            name: '看板模版-留存指标',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__KeepAnalyze" */ '../Analyze/KeepAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/KeepAnalyze').default,
            isIndicator: true,
            exact: true,
          },
          {
            path: '/overview_model/distributed_indicator',
            name: '看板模版-分布指标',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import(/* webpackChunkName: "p__Analyze__DistributedAnalyze" */ '../Analyze/DistributedAnalyze'),
                  LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                    .default,
                })
              : require('../Analyze/DistributedAnalyze').default,
            isIndicator: true,
            exact: true,
          },
        ],
      },
      {
        path: '/404',
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
              LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                .default,
            })
          : require('../Exception/404').default,
        exact: true,
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__Exception__404" */ '../Exception/404'),
              LoadingComponent: require('/data0/web/dida.98du.com/src/components/PageLoading/index')
                .default,
            })
          : require('../Exception/404').default,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
