/**
 * request 网络请求工具
 * 更详细的 api 文档: https://github.com/umijs/umi-request
 */
import { message, notification } from 'antd';
import { extend } from 'umi-request';
import { getCookie, getEnv, getPageQuery, isJson, loginOutCb } from './utils';

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

/**
 * 异常处理程序
 */
export const errorHandler = (error: { response: Response }): Response => {
  const { response } = error;
  if (response && response.status) {
    const errorText = codeMessage[response.status] || response.statusText;
    const { status, url } = response;

    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: errorText,
    });
  }
  return response;
};

const apiUrl = () => {
  let env = '';
  const allowedOrigins = ['http://dida.98du.com', 'https://dida.98du.com'];
  if (allowedOrigins.includes(window.location.origin)) {
    env = '//dida-java.98du.com';
  } else {
    env =
      process.env.NODE_ENV === 'development'
        ? '//dida-java.98du.com'
        : '//localdev.dida-java.98du.com';
  }
  return env;
};

/**
 * 配置request请求时的默认参数
 */
const request = extend({
  errorHandler, // 默认错误处理
  credentials: 'include', // 默认请求是否带上cookie
});

request.use(async (ctx, next) => {
  const { req } = ctx;
  const { options, url } = req;
  const { data = {} } = options || {};
  const path = window.location.pathname;

  // 外站（AB Test)嵌套页面，使用url上携带的 token进行登录
  const { token } = getPageQuery();
  if (token) {
    window.sessionStorage.setItem('token', token);
  }

  ctx.req.url = `${apiUrl()}${url}`;
  ctx.req.options = {
    ...options,
    headers: {
      'ida-token': token || window.sessionStorage.getItem('token') || getCookie('ida-token') || '',
      authToken: token || window.sessionStorage.getItem('token') || getCookie('ida-token') || '',
    },
    credentials: 'omit',
  };

  await next();
  const { res = {} } = ctx;
  const { code } = res;

  if (path !== window.location.pathname) {
    await next();
  }
  // 接口返回类型为非 JSON的直接返回（下载文件）
  if (!isJson(res)) {
    return res;
  }

  if (code === 401) {
    setTimeout(() => {
      loginOutCb();
    }, 300);
  }
  if (code !== 0 && !data.noErrorMsg) {
    message.error(res.msg || res.statusText || res.code);
  }
  return res;
});

export default request;
