import { EventItem, EventMenuItem } from '@/pages/Analyze/index.d';
import {
  addFunnel,
  deleteFunnel,
  fetchEventFilterList,
  fetchEventGroupList,
  fetchEventMeasureList,
  fetchEventMenuList,
  fetchEventReport,
  fetchFunnelById,
  fetchFunnelList,
  fetchFunnelReport,
  fetchKeepReport,
  cancelReport,
  updateFunnel,
  fetchCrowdFilterList,
  fetchAddictionReport,
  fetchCalFieldFilterList,
  // mockReport,
} from '@/services/analyze';
import { fetchBookmarkById } from '@/services/bookmark';
import { fetchIndicatorDetail } from '@/services/modal';
import { message } from 'antd';
import { EffectsCommandMap } from 'dva';
import { AnyAction, Reducer } from 'redux';
import { ConnectState } from './connect.d';

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: ConnectState) => T) => T },
) => void;

export interface IMeasure {
  func: string;
  func_id: number;
  metrics_id: string | number;
  metrics_name: string;
  // measure_type: number;
  operator: string[];
  option: string[];
  [key: string]: any;
}

export interface MeasureItem {
  field: string;
  event_attr_name: string;
  event_attr_id: string | number;
  children: IMeasure[];
}
export interface AnalyzeModelState {
  event_menu_list: [];
  defaultEventMenuItem?: EventItem;
  event_measure_data: { [key: string]: MeasureItem[] };
  public_event_filter_list: [];
  event_filter_config: {};
  event_group_list: [];
  bookmark: {};
  funnelList: [];
  initFunnel?: string;
  allEventIds?: string;
  funnelCache: {};
  funnelReport: {};
  crowdFilterList: [];
  addictionReport: {};
  cal_field_filter_list: any[];
}

export interface ModelType {
  namespace: string;
  state: AnalyzeModelState;
  effects: {
    fetchEventMenuList: Effect;
    fetchEventMeasureList: Effect;
    fetchPublicEventFilterList: Effect;
    fetchEventFilterConfig: Effect;
    fetchEventGroupList: Effect;
    fetchFunnelList: Effect;
    addFunnel: Effect;
    updateFunnel: Effect;
    fetchFunnelById: Effect;
    deleteFunnel: Effect;
    fetchEventReport: Effect;
    fetchKeepReport: Effect;
    fetchFunnelReport: Effect;
    fetchCrowdFilterList: Effect;
    fetchAddictionReport: Effect;
    [key: string]: Effect;
  };
  reducers: {
    saveAddictionReport: Reducer<AnalyzeModelState>;
    saveEventMenuList: Reducer<AnalyzeModelState>;
    saveEventMeasureList: Reducer<AnalyzeModelState>;
    savePublicEventFilterList: Reducer<AnalyzeModelState>;
    saveEventFilterConfig: Reducer<AnalyzeModelState>;
    saveCrowdFilterList: Reducer<AnalyzeModelState>;
    saveEventGroupList: Reducer<AnalyzeModelState>;
    saveFunnelList: Reducer<AnalyzeModelState>;
    saveFunnel: Reducer<AnalyzeModelState>;
    clear: Reducer<AnalyzeModelState>;
    [key: string]: Reducer<AnalyzeModelState>;
  };
}

const initState: AnalyzeModelState = {
  event_menu_list: [],
  defaultEventMenuItem: undefined,
  event_measure_data: {},
  public_event_filter_list: [],
  event_filter_config: {},
  event_group_list: [],
  bookmark: {},
  funnelList: [],
  initFunnel: undefined,
  allEventIds: undefined,
  funnelCache: {},
  funnelReport: {},
  crowdFilterList: [],
  addictionReport: {},
  cal_field_filter_list: [],
};

const Model: ModelType = {
  namespace: 'analyze',

  state: initState,

  effects: {
    *fetchEventMenuList(_, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchEventMenuList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventMenuList',
          payload: res.data,
        });
      }
    },
    *fetchEventMeasureList({ payload }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchEventMeasureList, { ...payload, product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventMeasureList',
          payload: { ...payload, data: res.data },
        });
      }
    },
    *fetchPublicEventFilterList({ payload }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchEventFilterList, { ...payload, product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'savePublicEventFilterList',
          payload: res.data,
        });
      }
    },
    *fetchCalFieldFilterList({ payload }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchCalFieldFilterList, { ...payload, product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCalFieldFilterList',
          payload: res.data,
        });
      }
    },
    *fetchEventFilterConfig({ payload }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchEventFilterList, { ...payload, product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventFilterConfig',
          payload: { key: payload.event_ids.join('_'), data: res.data },
        });
      }
    },
    *fetchCrowdFilterList({ payload }, { call, put }) {
      const res = yield call(fetchCrowdFilterList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveCrowdFilterList',
          payload: res.data,
        });
      }
    },
    *fetchEventGroupList({ payload }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchEventGroupList, { ...payload, product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventGroupList',
          payload: res.data,
        });
      }
    },
    *fetchBookmarkById({ payload }, { call, put }) {
      const res = yield call(fetchBookmarkById, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveBookMark',
          payload: res.data,
        });
      }
    },
    *fetchIndicatorDetail({ payload }, { call, put }) {
      const res = yield call(fetchIndicatorDetail, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveBookMark',
          payload: res.data,
        });
      }
    },
    *fetchFunnelList({ callback }, { call, put, select }) {
      const product_id = yield select(state => state.global.product_id);
      const res = yield call(fetchFunnelList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveFunnelList',
          payload: res.data,
        });
        callback && callback(res.data);
      }
    },
    *addFunnel({ payload, callback }, { call }) {
      const res = yield call(addFunnel, payload);
      if (res && +res.code === 0) {
        message.success('添加漏斗成功!');
        callback();
      }
    },
    *updateFunnel({ payload, callback }, { call }) {
      const res = yield call(updateFunnel, payload);
      if (res && +res.code === 0) {
        message.success('保存漏斗成功!');
        callback();
      }
    },
    *deleteFunnel({ payload, callback }, { call }) {
      const res = yield call(deleteFunnel, payload);
      if (res && +res.code === 0) {
        message.success('删除漏斗成功!');
        callback && callback();
      }
    },
    *fetchFunnelById({ payload, callback, errorCb }, { call, put }) {
      const { id } = payload;
      const res = yield call(fetchFunnelById, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveFunnel',
          payload: { id, data: res.data },
        });
        callback && callback(res.data);
      } else {
        errorCb && errorCb();
      }
    },
    *fetchEventReport({ payload, callback }, { call }) {
      const res = yield call(fetchEventReport, payload);
      if (res) {
        if (+res.code === 0) {
          callback(res.data);
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    *fetchKeepReport({ payload, callback }, { call }) {
      const res = yield call(fetchKeepReport, payload);
      if (res && +res.code === 0) {
        callback(res.data);
      } else {
        callback();
      }
    },
    *fetchFunnelReport({ payload, callback }, { call, put }) {
      const res = yield call(fetchFunnelReport, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveFunnelReport',
          payload: res.data,
        });
        callback(res.data);
      } else {
        callback();
      }
    },
    *fetchAddictionReport({ payload, callback }, { call, put }) {
      const res = yield call(fetchAddictionReport, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveAddictionReport',
          payload: res.data,
        });
        callback(res.data);
      } else {
        callback();
      }
    },
    *cancelReport({ payload }, { call }) {
      yield call(cancelReport, payload);
    },
  },

  reducers: {
    saveEventMenuList(state = initState, { payload }) {
      const allEventItems = payload.reduce((prev: EventItem[], cur: EventMenuItem) => {
        const { events: eventItems, ...rest } = cur;
        return prev.concat(eventItems.map(i => ({ ...i, ...rest })));
      }, []);
      return {
        ...state,
        event_menu_list: payload,
        defaultEventMenuItem: allEventItems[0],
      };
    },
    saveEventMeasureList(state = initState, { payload }) {
      const { event_measure_data } = state;
      const { event_id, data } = payload;
      return {
        ...state,
        event_measure_data: { ...event_measure_data, [event_id]: data },
      };
    },
    savePublicEventFilterList(state = initState, { payload }) {
      return {
        ...state,
        public_event_filter_list: payload,
      };
    },
    saveEventFilterConfig(state = initState, { payload }) {
      const { event_filter_config } = state;
      const { key, data } = payload;
      return {
        ...state,
        event_filter_config: { ...event_filter_config, [key]: data },
      };
    },
    saveCalFieldFilterList(state = initState, { payload }) {
      return {
        ...state,
        cal_field_filter_list: payload ? [payload] : [],
      };
    },
    saveCrowdFilterList(state = initState, { payload }) {
      return {
        ...state,
        crowdFilterList: payload,
      };
    },
    saveEventGroupList(state = initState, { payload }) {
      return {
        ...state,
        event_group_list: payload,
      };
    },
    saveBookMark(state = initState, { payload }) {
      return {
        ...state,
        bookmark: payload,
      };
    },
    saveFunnelList(state = initState, { payload }) {
      const initFunnel =
        Array.isArray(payload) && payload.length ? String(payload[0].id) : undefined;
      return {
        ...state,
        funnelList: payload,
        initFunnel,
      };
    },
    saveAllEventIds(state = initState, { payload = [] }) {
      return {
        ...state,
        allEventIds: payload.sort((a: string, b: string) => +a - +b).join('_'),
      };
    },
    saveFunnel(state = initState, { payload }) {
      const { funnelCache } = state;
      const { id, data } = payload;
      return {
        ...state,
        funnelCache: { ...funnelCache, [id]: data },
      };
    },
    saveFunnelReport(state = initState, { payload }) {
      return {
        ...state,
        funnelReport: payload,
      };
    },
    saveAddictionReport(state = initState, { payload }) {
      return {
        ...state,
        addictionReport: payload,
      };
    },
    clearData(state = initState) {
      return {
        ...state,
        bookmark: {},
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
