import request from '@/utils/request';
import { stringify } from 'qs';
import { Params } from './index.d';

export async function fetchRoleList(params: Params): Promise<any> {
  return request.post('/api/sys/role/list', { data: params });
}

export async function updateRole(params: Params): Promise<any> {
  return request.post('/api/sys/role/update', {
    data: params,
  });
}

export async function addRole(params: Params): Promise<any> {
  return request.post('/api/sys/role/add', {
    data: params,
  });
}

export async function deleteRole(params: Params): Promise<any> {
  return request(`/api/sys/role/remove?${stringify(params)}`);
}
