import React from 'react';
import { Spin } from 'antd';

interface Props {
  size?: 'default' | 'small' | 'large';
  padding?: number | string;
}

export default function PageLoading(props: Props) {
  const { size = 'large', padding = 100 } = props;
  return (
    <div style={{ padding, textAlign: 'center' }}>
      <Spin size={size} />
    </div>
  );
}
