import {
  fetchSysMenuList,
  fetchSysUserList,
  fetchMenuTree,
  fetchRoleList,
  fetchEventTypeList,
  fetchEventTree,
  fetchDeptList,
  fetchDeptTree,
  updateUser,
  fetchUserListByQuery,
  fetchUserTree,
  fetchLogList,
} from '@/services/system';
import { message } from 'antd';
import { Reducer } from 'redux';
import { Effect } from './connect.d';

export interface SystemState {
  data: {};
  systemMenu: [];
  menuTree: {};
  roleList: [];
  eventTypeList: [];
  eventTree: [];
  deptList: {};
  deptTree: {};
  userTree: {};
  logList: {};
  [key: string]: any;
}

export interface ModelType {
  namespace: string;
  state: SystemState;
  effects: {
    [key: string]: Effect;
  };
  reducers: {
    [key: string]: Reducer<SystemState>;
  };
}

const initState: SystemState = {
  data: {},
  systemMenu: [],
  menuTree: {},
  roleList: [],
  eventTypeList: [],
  eventTree: [],
  deptList: {},
  deptTree: {},
  userTree: {},
  logList: {},
};

const Model: ModelType = {
  namespace: 'system',

  state: initState,

  effects: {
    *fetchSysMenuList({ payload }, { call, put }) {
      const res = yield call(fetchSysMenuList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveSystemMenu',
          payload: res.data,
        });
      }
    },
    *fetchSysUserList({ payload }, { call, put }) {
      const res = yield call(fetchSysUserList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveData',
          payload: res.data,
        });
      }
    },
    *updateUser({ payload, callback }, { call }) {
      const res = yield call(updateUser, payload);
      if (res && +res.code === 0) {
        message.success('修改成功!');
        callback && callback();
      }
    },
    *fetchMenuTree({ payload }, { call, put }) {
      const res = yield call(fetchMenuTree, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveMenuTree',
          payload: res.data,
        });
      }
    },
    *fetchRoleList(_, { call, put, select }) {
      // const systemState = yield select(state => state.system);
      const product_id = yield select(state => state.global.product_id);

      // const { roleList } = systemState;
      // if (Array.isArray(roleList) && roleList.length) {
      //   return;
      // }
      const res = yield call(fetchRoleList, { product_id });
      if (res && +res.code === 0) {
        yield put({
          type: 'saveRoleList',
          payload: res.data,
        });
      }
    },
    *fetchEventTypeList({ payload }, { call, put }) {
      const res = yield call(fetchEventTypeList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventTypeList',
          payload: res.data.eventModuleList,
        });
      }
    },
    *fetchEventTree({ payload }, { call, put }) {
      const res = yield call(fetchEventTree, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveEventTree',
          payload: res.data,
        });
      }
    },
    *fetchDeptList(_, { call, put }) {
      const res = yield call(fetchDeptList);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveDeptList',
          payload: res.data,
        });
      }
    },
    *fetchDeptTree(_, { call, put }) {
      const res = yield call(fetchDeptTree);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveDeptTree',
          payload: res.data,
        });
      }
    },
    *fetchUserListByQuery({ payload }, { call, put }) {
      const res = yield call(fetchUserListByQuery, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveData',
          payload: res.data,
        });
      }
    },
    *fetchUserTree({ payload }, { call, put, select }) {
      const systemState = yield select(state => state.system);
      const { userTree } = systemState;
      if (Array.isArray(userTree.children) && userTree.children.length) {
        return;
      }
      const res = yield call(fetchUserTree, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserTree',
          payload: res.data,
        });
      }
    },
    *fetchLogList({ payload }, { call, put }) {
      const res = yield call(fetchLogList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveLogList',
          payload: res.data,
        });
      }
    },
  },

  reducers: {
    saveData(state = initState, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    saveSystemMenu(state = initState, { payload }) {
      return {
        ...state,
        systemMenu: payload,
      };
    },
    saveMenuTree(state = initState, { payload }) {
      return {
        ...state,
        menuTree: payload,
      };
    },
    saveRoleList(state = initState, { payload }) {
      return {
        ...state,
        roleList: payload,
      };
    },
    saveEventTypeList(state = initState, { payload }) {
      return {
        ...state,
        eventTypeList: payload,
      };
    },
    saveEventTree(state = initState, { payload }) {
      return {
        ...state,
        eventTree: payload,
      };
    },
    saveDeptList(state = initState, { payload }) {
      return {
        ...state,
        deptList: payload,
      };
    },
    saveDeptTree(state = initState, { payload }) {
      return {
        ...state,
        deptTree: payload,
      };
    },
    saveUserTree(state = initState, { payload }) {
      return {
        ...state,
        userTree: payload,
      };
    },
    saveLogList(state = initState, { payload }) {
      return {
        ...state,
        logList: payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default Model;
