import request from '@/utils/request';
import { stringify } from 'qs';
import { Params } from './index.d';

export async function fetchSysMenuList(): Promise<any> {
  return request('/api/sys/menu/list');
}

export async function fetchSysUserList(params: Params): Promise<any> {
  return request(`/api/sys/user/list?${stringify(params)}`);
}

export async function fetchMenuTree(params: Params): Promise<any> {
  return request(`/api/sys/menu/tree?${stringify(params)}`);
}

export async function fetchRoleList(params: Params): Promise<any> {
  return request.post('/api/sys/role/list', { data: params });
}

export async function fetchEventTypeList(params: Params): Promise<any> {
  return request.post(`/api/metadata/event_module/list`, { data: params });
}

export async function fetchEventTree(params: Params): Promise<any> {
  return request(`/api/metadata/event/list_tree?${stringify(params)}`);
}

export async function fetchDeptList(): Promise<any> {
  return request('/api/sys/sysDept/list');
}

export async function fetchDeptTree(): Promise<any> {
  return request('/api/sys/sysDept/tree');
}

export async function updateUser(params: Params): Promise<any> {
  return request.post('/api/sys/user/update', {
    data: params,
  });
}

export async function fetchUserListByQuery(params: Params): Promise<any> {
  return request.post('/api/sys/user/queryUser', {
    data: params,
  });
}

export async function fetchUserTree(params: Params = {}): Promise<any> {
  return request(`/api/sys/user/tree?${stringify(params)}`);
}

/**
 * 系统使用情况报表--日志明细
 */
export async function fetchLogList(params: Params): Promise<any> {
  return request.post('/api/sys/log/log_list', {
    data: params,
  });
}

/**
 * 系统使用情况报表--系统使用情况
 */
export async function fetchMonitorList(params: Params): Promise<any> {
  return request.post('/api/sys/log/system_monitor', {
    data: params,
  });
}

/**
 * 系统使用情况报表--用户查询效率情况
 */
export async function fetchUserMonitor(params: Params): Promise<any> {
  return request.post('/api/sys/log/user_query_monitor', {
    data: params,
  });
}

/**
 * 系统使用情况报表--用户查询效率情况
 */
export async function fetchTypeMonitor(params: Params): Promise<any> {
  return request.post('/api/sys/log/analysis_query_monitor', {
    data: params,
  });
}

/**
 * 系统使用情况报表--看板运行情况
 */
export async function fetchDashboardMonitor(params: Params): Promise<any> {
  return request.post('/api/sys/log/dashboard_monitor', {
    data: params,
  });
}

/**
 * 系统使用情况报表--看板使用情况
 */
export async function fetchLookDashboardMonitor(params: Params): Promise<any> {
  return request.post('/api/sys/log/look_dashboard_monitor', {
    data: params,
  });
}

/**
 * 用户管理-重置密码
 */
export async function resetPwd(params: Params): Promise<any> {
  return request.post('/api/sys/user/resetPwd', {
    data: params,
  });
}

/**
 * 用户管理--新增用户
 */
export async function addUser(params: Params): Promise<any> {
  return request.post('/api/sys/user/add', {
    data: params,
  });
}
