import request from '@/utils/request';
import { stringify } from 'qs';
import { Params } from './index.d';

export async function accountLogin(params: Params): Promise<any> {
  return request.post(`/api/login?${stringify(params)}`, {
    data: params,
  });
}

// export async function loginByOa(params: Params): Promise<any> {
//   return request(`/api/oalogin?${stringify(params)}`);
// }

export async function loginByOa(params: Params): Promise<any> {
  return request(`/api/auth_login?${stringify(params)}`);
}

export async function ssoLogin(params: Params): Promise<any> {
  return request.post('/admin/main/login', {
    data: params,
  });
}
