import { fetchReportList } from '@/services/dashboard_report';
import { Reducer } from 'redux';
import { Effect } from './connect.d';
import { IReportListItem } from './dashboard_report.d';

export interface DashboardReportModelState {
  reportList: IReportListItem[];
}

export interface DashboardReportModelType {
  namespace: string;
  state: {
    reportList: IReportListItem[];
  };
  effects: {
    fetchReportList: Effect;
  };
  reducers: {
    saveReportList: Reducer<DashboardReportModelState>;
  };
}

const initState = { reportList: [] };

const Model: DashboardReportModelType = {
  namespace: 'dashboard_report',

  state: initState,

  effects: {
    *fetchReportList({ payload }, { call, put }) {
      const res = yield call(fetchReportList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveReportList',
          payload: res.data,
        });
      }
    },
  },

  reducers: {
    saveReportList(state, { payload }) {
      return {
        ...state,
        reportList: payload,
      };
    },
  },
};

export default Model;
