import request from '@/utils/request';
import { Params } from './index.d';

export async function fetchTaskList(params: Params): Promise<any> {
  return request.post('/api/task/list', {
    data: params,
  });
}

export async function updateTask(params: Params): Promise<any> {
  return request.post('/api/task/update', {
    data: params,
  });
}

export async function supplyTask(params: Params): Promise<any> {
  return request.post('/api/task/supplyTask', {
    data: params,
  });
}

export async function supplyTaskByDashboard(params: Params): Promise<any> {
  return request.post('/api/task/supplyTaskByDashBoard', {
    data: params,
  });
}

export async function supplyTaskByEvent(params: Params): Promise<any> {
  return request.post('/api/task/supplyTaskByEvent', {
    data: params,
  });
}

export async function supplyTaskByBookmark(params: Params): Promise<any> {
  return request.post('/api/task/supplyTaskByBookMark', {
    data: params,
  });
}

export async function fetchTaskInstanceList(params: Params): Promise<any> {
  return request.post('/api/task_instance/list', {
    data: params,
  });
}

export async function updateTaskInstance(params: Params): Promise<any> {
  return request.post('/api/task_instance/updateStatus', {
    data: params,
  });
}

export async function fetchTaskInstanceLogDetail(params: Params): Promise<any> {
  return request.post('/api/task_instance/logDetail', {
    data: params,
  });
}

export async function fetchDashboard(params: Params): Promise<any> {
  return request.post('/api/dashboard/all_list', {
    data: params,
  });
}

export async function fetchBookmark(params: Params): Promise<any> {
  return request.post('/api/bookmark/all_list', {
    data: params,
  });
}

export async function fetchEvent(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/all_list', {
    data: params,
  });
}

export async function fetchCrowdList(params: Params): Promise<any> {
  return request.post('/api/metadata/crowd_task/list', {
    data: params,
  });
}

export async function supplyCrownTask(params: Params): Promise<any> {
  return request.post('/api/metadata/crowd_task/supply_crowd', {
    data: params,
  });
}

export async function fetchCrowdInstanceList(params: Params): Promise<any> {
  return request.post('/api/metadata/crowd_instance/list', {
    data: params,
  });
}

export async function fetchCrowdInstanceLogDetail(params: Params): Promise<any> {
  return request.post('/api/metadata/crowd_instance/log_detail', {
    data: params,
  });
}

export async function rerunCrowdInstance(params: Params): Promise<any> {
  return request.post('/api/metadata/crowd_instance/rerun_instance', {
    data: params,
  });
}
