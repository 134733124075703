import request from '@/utils/request';
import { Params } from './index.d';

export async function fetchConfigList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_config/list', {
    data: params,
  });
}

export async function addConfig(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_config/add', {
    data: params,
  });
}

export async function updateConfig(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_config/update', {
    data: params,
  });
}

export async function fetchInstanceFileList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_instance_file/list', {
    data: params,
  });
}

export async function removeInstanceFile(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_instance_file/remove', {
    data: params,
  });
}

export async function updateInstanceFile(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_instance_file/update', {
    data: params,
  });
}

export async function fetchTaskList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task/list', {
    data: params,
  });
}

export async function addTask(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task/add', {
    data: params,
  });
}

export async function updateTask(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task/update', {
    data: params,
  });
}

export async function removeTask(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task/remove', {
    data: params,
  });
}

export async function fetchTaskInstanceList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task_instance/list', {
    data: params,
  });
}

export async function addTaskInstance(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task_instance/add', {
    data: params,
  });
}

export async function updateTaskInstance(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task_instance/update', {
    data: params,
  });
}

export async function removeTaskInstance(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task_instance/remove', {
    data: params,
  });
}


/*
*  上传-任务实例管理-日志查询
*/
export async function getInstanceLog(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_task_instance/log', {
    data: params,
  });
}

/*
*  上传-上传监控管理-列表
*/
export async function getMonitorList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_monitor/list', {
    data: params,
  });
}

/*
*  上传-上传监控管理-添加
*/
export async function addMonitorList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_monitor/add', {
    data: params,
  });
}

/*
*  上传-上传监控管理-更新
*/
export async function updateMonitorList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_monitor/update', {
    data: params,
  });
}

/*
*  上传-上传监控管理-废止
*/
export async function removeMonitorList(params: Params): Promise<any> {
  return request.post('/api/metadata/upload_monitor/remove', {
    data: params,
  });
}
