import { Reducer } from 'redux';
import { Model } from 'dva';
import { message } from 'antd';
import {
  fetchTaskList,
  updateTask,
  supplyTask,
  supplyTaskByBookmark,
  supplyTaskByDashboard,
  supplyTaskByEvent,
  fetchTaskInstanceList,
  updateTaskInstance,
  fetchTaskInstanceLogDetail,
  fetchDashboard,
  fetchBookmark,
  fetchEvent,
  fetchCrowdList,
  supplyCrownTask,
  fetchCrowdInstanceList,
  fetchCrowdInstanceLogDetail,
  rerunCrowdInstance,
} from '@/services/task';
import { IEffect } from './connect.d';
import { extendModal } from '@/utils/utils';
import BookmarkModel from './bookmark';

export interface TaskState {
  task_list: {};
  task_instance_list: {};
  bookmark_list: {};
  user_task_list: {};
  user_task_instance_list: {};
}

export interface ModelType extends Model {
  namespace: string;
  state: TaskState;
  effects: {
    fetchTaskList: IEffect;
    updateTask: IEffect;
    supplyTask: IEffect;
    fetchTaskInstanceList: IEffect;
    updateTaskInstance: IEffect;
    fetchTaskInstanceLogDetail: IEffect;
    fetchDashboard: IEffect;
    fetchBookmark: IEffect;
    fetchEvent: IEffect;
    fetchCrowdList: IEffect;
    fetchCrowdSearchList: IEffect;
    supplyCrownTask: IEffect;
    fetchCrowdInstanceList: IEffect;
    fetchCrowdInstanceLogDetail: IEffect;
    rerunCrowdInstance: IEffect;
  };
  reducers: {
    saveTaskList: Reducer<TaskState>;
    saveTaskInstanceList: Reducer<TaskState>;
    clear: Reducer<TaskState>;
    saveUserTaskList: Reducer<TaskState>;
    saveUserTaskInstanceList: Reducer<TaskState>;
  };
}

const initState: TaskState = {
  task_list: {},
  task_instance_list: {},
  bookmark_list: {},
  user_task_list: {},
  user_task_instance_list: {},
};

const TaskModel: ModelType = {
  namespace: 'task',

  state: initState,

  effects: {
    *fetchTaskList({ payload, callback }, { call, put }) {
      const res = yield call(fetchTaskList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTaskList',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchCrowdList({ payload, callback }, { call, put }) {
      const res = yield call(fetchCrowdList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserTaskList',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchCrowdSearchList({ payload, callback }, { call, put }) {
      const res = yield call(fetchCrowdList, payload);
      if (res && +res.code === 0) {
        typeof callback === 'function' && callback(res.data);
      }
    },
    *updateTask({ payload, callback }, { call }) {
      const res = yield call(updateTask, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *supplyTask({ payload, callback }, { call }) {
      const { bookmark_ids, dashboard_ids, event_ids } = payload;
      let supplyReq = null;

      if (bookmark_ids) {
        supplyReq = supplyTaskByBookmark;
      } else if (dashboard_ids) {
        supplyReq = supplyTaskByDashboard;
      } else if (event_ids) {
        supplyReq = supplyTaskByEvent;
      } else {
        supplyReq = supplyTask;
      }

      const res = yield call(supplyReq, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *supplyCrownTask({ payload, callback }, { call }) {
      const res = yield call(supplyCrownTask, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchTaskInstanceList({ payload, callback }, { call, put }) {
      const res = yield call(fetchTaskInstanceList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTaskInstanceList',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchCrowdInstanceList({ payload, callback }, { call, put }) {
      const res = yield call(fetchCrowdInstanceList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveUserTaskInstanceList',
          payload: res.data,
        });
        typeof callback === 'function' && callback(res.data);
      }
    },
    *updateTaskInstance({ payload, callback }, { call }) {
      const res = yield call(updateTaskInstance, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *rerunCrowdInstance({ payload, callback }, { call }) {
      const res = yield call(rerunCrowdInstance, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchTaskInstanceLogDetail({ payload, callback }, { call }) {
      const res = yield call(fetchTaskInstanceLogDetail, payload);
      if (res && +res.code === 0) {
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchCrowdInstanceLogDetail({ payload, callback }, { call }) {
      const res = yield call(fetchCrowdInstanceLogDetail, payload);
      if (res && +res.code === 0) {
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchDashboard({ payload, callback }, { call }) {
      const res = yield call(fetchDashboard, payload);
      if (res && +res.code === 0) {
        typeof callback === 'function' && callback(res.data);
      }
    },
    *fetchBookmark({ payload, callback }, { call }) {
      const res = yield call(fetchBookmark, payload);
      if (res && +res.code === 0) {
        const { bookmarkList = [] } = res.data;
        typeof callback === 'function' && callback(bookmarkList);
      }
    },
    *fetchEvent({ payload, callback }, { call }) {
      const res = yield call(fetchEvent, payload);
      if (res && +res.code === 0) {
        typeof callback === 'function' && callback(res.data);
      }
    },
  },

  reducers: {
    saveTaskList(state = initState, { payload }) {
      return {
        ...state,
        task_list: payload,
      };
    },
    saveTaskInstanceList(state = initState, { payload }) {
      return {
        ...state,
        task_instance_list: payload,
      };
    },
    saveUserTaskList(state = initState, { payload }) {
      return {
        ...state,
        user_task_list: payload,
      };
    },
    saveUserTaskInstanceList(state = initState, { payload }) {
      return {
        ...state,
        user_task_instance_list: payload,
      };
    },
    clear() {
      return initState;
    },
  },
};

export default extendModal(BookmarkModel, TaskModel);
