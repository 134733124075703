import request from '@/utils/request';
import { Params } from './index.d';

/**
 * 看板模板--业务类型列表
 */
export async function fetchTypeList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/business_type_list', {
    data: params,
  });
}

/**
 * 看板模板--模板列表
 */
export async function fetchTemplateList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/list', {
    data: params,
  });
}

/**
 * 看板模板--新增模板
 */
export async function addTemplate(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/add_template', {
    data: params,
  });
}

/**
 * 看板模板--编辑模板
 */
export async function updateTemplate(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/update_template', {
    data: params,
  });
}

/**
 * 看板模板--删除模板
 */
export async function removeTemplate(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/remove_template', {
    data: params,
  });
}

/**
 * 看板模板--指标类型列表
 */
export async function fetchTargetList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/indicator_type_list', {
    data: params,
  });
}

/**
 * 看板模板--删除指标类型
 */
export async function removeTargetList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/remove_indicator_type', {
    data: params,
  });
}

/**
 * 看板模板--新增指标类型
 */
export async function addTargetList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/add_indicator_type', {
    data: params,
  });
}

/**
 * 看板模板--更新指标类型
 */
export async function updateTargetList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/update_indicator_type', {
    data: params,
  });
}

/**
 * 看板模板--指标列表
 */
export async function fetchIndicatorList(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/indicator_list', {
    data: params,
  });
}

/**
 * 看板模板--指标详情
 */
export async function fetchIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/indicator_detail', {
    data: params,
  });
}

/**
 * 看板模板--新增指标
 */
export async function addIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/add_indicator', {
    data: params,
  });
}

/**
 * 看板模板--更新指标
 */
export async function updateIndicator(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/update_indicator', {
    data: params,
  });
}

/**
 * 看板模板--删除指标
 */
export async function removeIndicator(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/remove_indicator', {
    data: params,
  });
}

/**
 * 看板模板--更新指标详情
 */
export async function updateIndicatorDetail(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/update_indicator_detail', {
    data: params,
  });
}
