import { Model } from 'dva';
import FileSaver from 'file-saver';
import Cookie from 'js-cookie';
import moment from 'moment';
import { Moment } from 'moment/moment.d';
// import 'pinyin4js';
import { parse } from 'qs';
import { router } from 'umi';
// import XLSX from 'xlsx';
// import { PROD_HOST, TEST_HOST } from '../../config/config';
/* eslint no-useless-escape:0 import/prefer-default-export:0 */
const reg = /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(?::\d+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/;

const isUrl = (path: string): boolean => reg.test(path);

export { isUrl };

/**
 * 设置Cookie
 * @param  {String} key   cookie-key
 * @param  {String} value cookie-value
 * @param  {Number} seconds  expire seconds
 */
export const setCookie = (key: string, value = '', seconds = -1) => {
  Cookie.set(key, value, {
    expires: seconds,
  });
};

/**
 * 获取Cookie
 * @param  {String} key cookie-key
 * @return {Any}    value cookie-value
 */
export const getCookie = (key: string): string | any => Cookie.get(key);

/**
 * Delete Cookie
 * @param  {String} key cookie-key
 */
export const deleteCookie = (key: string): void => {
  Cookie.remove(key);
};

export const clearAllCookie = (): void => {
  const cookieKeys = Cookie.get();
  Object.keys(cookieKeys).forEach(i => Cookie.remove(i));
};
/**
 * 获取Token
 */
export const getAuth = (): string => getCookie('ida-token');

/**
 * 存储localStorage
 */
export const setStore = (name: string, content: any): void => {
  if (!name) return;
  let cont = content;
  if (typeof content !== 'string') {
    cont = JSON.stringify(content);
  }
  window.localStorage.setItem(name, cont);
};

/**
 * 获取localStorage
 */
export const getStore = (name: string): string | null => {
  if (!name) return null;
  return window.localStorage.getItem(name);
};

/**
 * 删除localStorage
 */
export const removeStore = (name: string): void => {
  if (!name) return;
  window.localStorage.removeItem(name);
};
/**
 * 清空localStorage
 * @param names
 */
export const clearStores = (names: string[]): void => {
  names.forEach(i => {
    removeStore(i);
  });
};

/**
 * 读取cookie，判断是否登录
 * @return {boolean} 是否登录
 */
export const isLogin = (): boolean => !!Cookie.get('ida-token') && !!getStore('product_id');

export function getPageQuery(): {
  [key: string]: string;
} {
  return parse(window.location.href.split('?')[1]);
}

// 匹配中文全拼或首字母
export const getFilterOption = (input = '', text = '') => {
  const inputLower = input.toLowerCase();
  if (/^[a-zA-Z]/.test(input)) {
    const shortLetter = window.PinyinHelper.getShortPinyin(text);
    if (shortLetter.toLowerCase().indexOf(inputLower) >= 0) {
      return true;
    }
    const fullLetter = window.PinyinHelper.convertToPinyinString(
      text,
      '',
      window.PinyinFormat.WITHOUT_TONE,
    );
    return fullLetter.toLowerCase().indexOf(inputLower) >= 0;
  }
  return text.toLowerCase().indexOf(inputLower) >= 0;
};

/**
 *
 *
 * @export
 * @param {Number} start
 * @param {Number} end
 * @returns
 */
export function getLastDays(start: number, end: number): [Moment, Moment] {
  return [moment().subtract('days', end), moment().subtract('days', start)];
}

/**
 * 计算时间差, 返回两个事件的差值
 * @param {String} date1
 * @param {String} date2
 * @returns {Number}
 */
export function getDiffDays(date1: string, date2: string): number {
  return Math.abs(moment(date2).diff(moment(date1)) / 86400000);
}

export function dateFormat(data: any[] = [], format = 'YYYYMMDD') {
  return data.map(i => {
    if (moment.isMoment(i)) {
      return i.format(format);
    }
    return moment(i).isValid() ? moment(i).format(format) : i;
  });
}
export function dateFormatEndof() {
  return moment()
    .endOf('day')
    .format('YYYY-MM-DD HH:mm:ss');
}

export function filterNull<T>(opt: T): T {
  const result = {} as T;
  for (const [key, value] of Object.entries(opt)) {
    if (value && typeof value === 'object') {
      if (Array.isArray(value)) {
        result[key] = Object.values(filterNull(value));
      } else {
        result[key] = filterNull(value);
      }
    } else {
      result[key] = value !== null ? value : undefined;
    }
  }
  return result;
}

/**
 * 通过对象生成{key, name}数组
 * @param opt
 * @returns {array}
 */
export const generateOpts = (opt: { [x: string]: any }) =>
  opt ? Object.keys(opt).map(op => ({ name: opt[op], value: op })) : [];

/**
 * 判断部署的环境
 */
export const getEnv = (): string => {
  let env = 'dev';
  if (window.location.origin === 'http://localdev.dida.98du.com') {
    env = 'test';
  }
  if (window.location.origin === 'http://dida.98du.com') {
    env = 'prod';
  }
  return env;
};

export const getFlatMenu = (data: any[] = []) => {
  let result = {};
  data.forEach(item => {
    result[item.id] = item;
    if (item.children && item.children.length) {
      result = { ...result, ...getFlatMenu(item.children) };
    }
  });
  return result;
};

const check = (origin: any, cache: any[], count: object) => {
  if (process.env.NODE_ENV !== 'production') {
    for (const key in origin) {
      if (cache.indexOf(key) === -1) {
        cache.push(key);
      } else {
        // eslint-disable-next-line no-param-reassign
        count[key] = count[key] ? count[key] + 1 : 1;
      }
    }
  }
};

/**
 * 合并 modals
 * @param models 传入的模块（多个逗号隔开）
 * @example
 */
export const extendModal = (...models: Model[]) => {
  const base: Model = { namespace: '', state: {}, subscriptions: {}, effects: {}, reducers: {} };
  const stateCache: any[] = [];
  const stateCount = {};
  const subscriptionsCache: any[] = [];
  const subscriptionsCount = {};
  const effectsCache: any[] = [];
  const effectsCount = {};
  const reducersCache: any[] = [];
  const reducersCount = {};

  const model = models.reduce((acc: Model, extend: Model) => {
    acc.namespace = extend.namespace;
    if (typeof extend.state === 'object' && !Array.isArray(extend.state)) {
      check(extend.state, stateCache, stateCount);
      Object.assign(acc.state, extend.state);
    } else if ('state' in extend) {
      acc.state = extend.state;
    }
    check(extend.subscriptions, subscriptionsCache, subscriptionsCount);
    Object.assign(acc.subscriptions, extend.subscriptions);
    check(extend.effects, effectsCache, effectsCount);
    Object.assign(acc.effects, extend.effects);
    check(extend.reducers, reducersCache, reducersCount);
    Object.assign(acc.reducers, extend.reducers);
    return acc;
  }, base);

  return model;
};

type CompareArrayParams = Array<string | number>;
/**
 *
 * @param {array<string | number>} arr1
 * @param {array<string | number>} arr2
 * @returns {boolean}
 */
export function isDiffArray(arr1: CompareArrayParams, arr2: CompareArrayParams) {
  let result = false;
  if (arr1.length !== arr2.length) {
    result = true;
  } else {
    result = arr1.some(i => !arr2.map(String).includes(String(i)));
  }
  return result;
}

export function getStrLength(str = '') {
  const arr = str.split('');
  let len = 0;
  arr.forEach(i => {
    if (/\w/.test(i)) {
      len += 0.5;
    } else {
      len += 1;
    }
  });
  return Math.ceil(len);
}

export const download = (wbout: any, title?: string) => {
  try {
    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), title);
  } catch (e) {
    // eslint-disable-next-line no-console
    if (typeof console !== 'undefined') console.log(e, wbout);
  }
  return wbout;
};

export const downloadUTF8 = (source: any, title?: string) => {
  try {
    FileSaver.saveAs(new Blob([`\ufeff${source}`], { type: 'text/csv,charset=UTF-8' }), title);
  } catch (e) {
    // eslint-disable-next-line no-console
    if (typeof console !== 'undefined') console.log(e, source);
  }
  return source;
};

// 导出EXCEL表格
export const exportXLSX = (
  data: { columnsData: { key: string; title: string }[]; rowsData: {}[]; name: string }[],
  title: string,
  mergeSheet?: { title: string; sheet: XLSX.WorkSheet },
) => {
  const wb = XLSX.utils.book_new();

  if (mergeSheet) {
    XLSX.utils.book_append_sheet(wb, mergeSheet.sheet, mergeSheet.title);
  }

  const EXT = 'xls';
  const duplicateName = {}; // 标记是否有重复书签名
  data.forEach(d => {
    const { columnsData, rowsData, name } = d;
    let sheetName = name;
    // 书签名重复处理
    if (duplicateName.hasOwnProperty(`${name}`)) {
      duplicateName[sheetName] += 1;
      sheetName = `${sheetName}(${duplicateName[sheetName]})`;
    } else {
      duplicateName[sheetName] = 0;
    }
    // 书签名特殊符号处理：\ / ? * [ ]
    sheetName = sheetName.replace(/[\[\]\*\?\/\\]/g, '-').substr(0, 31);

    const header = {};
    columnsData.forEach(c => {
      header[c.key] = c.title;
    });

    const rowsFormat = rowsData
      .map((r: AnyObj) => {
        const { key, children, ...rest } = r;
        return children
          ? [
              rest,
              children.map((c: AnyObj) => {
                const { key: keys, ...crest } = c;
                return crest;
              }),
            ].reduce((a, v) => a.concat(v), [])
          : r;
      })
      .reduce((a, v) => a.concat(v), [])
      .map((r: AnyObj) => {
        columnsData.forEach(c => {
          const temp = r[c.key];
          r[c.key] = +temp ? +temp : temp;
        });
        return r;
      });

    const ws = XLSX.utils.json_to_sheet([header, ...rowsFormat], {
      header: Object.keys(header),
      skipHeader: true,
    });

    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });

  const wbout = XLSX.write(wb, {
    bookType: EXT,
    bookSST: false,
    type: 'array',
  });

  download(wbout, `${title}.${EXT}`);
};

/**
 *
 *
 * @export
 * @param {string | number[]} arr
 * @returns
 */
export function uniqArray(arr: Array<number | string>) {
  return [...new Set(arr)];
}

/**
 * 退出登录的回调
 *
 * @export
 */
export function loginOutCb() {
  // const loginType = getStore('login_type');
  deleteCookie('ida-token');
  deleteCookie('sso-product-id');
  deleteCookie('uid');
  if (!/login/.test(window.location.pathname)) {
    setStore('lastPath', window.location.href.replace(window.location.origin, ''));
  }
  // if (!loginType || loginType === 'oa') {
  // router.replace('/login/by_oa');
  // } else {
  // router.replace('/login');
  // }

  if (self != top) {
    window.parent.postMessage(
      {
        logout: true,
      },
      '*',
    );
  } else {
    router.replace('/login');
  }
}

// 检查权限
export function isPerms(perms: string) {
  const data = getCookie('perms') || '';
  return data.split(',').includes(perms);
}

// 拼合数组
export const flatten = (arr: any[]) => arr.reduce((a, v) => a.concat(v), []);

export const perToNum = (value: string | number) => {
  if (typeof value === 'number') {
    return value;
  }
  const has_per = value.indexOf('%') !== -1;
  return has_per ? +value.slice(0, -1) : +value;
};
/**
 * 获取数组项为真值的索引数组
 * @param {any[]} arr
 * @returns {number[]}
 */
export function getArrTruthIndex(arr: any[] = []) {
  return arr.reduce(
    (prev: number[], cur: any, index: number) => (cur ? [...prev, index] : prev),
    [],
  );
}

export const isJson = (obj: any) =>
  typeof obj === 'object' &&
  Object.prototype.toString.call(obj) === '[object Object]' &&
  !obj.length;

/**
 * 映射匹配
 */
export function matchResult(value: string | number, map = {}) {
  return map[value] || value;
}

export function cloneNode(node: any, javascriptEnabled?: boolean) {
  const clone =
    node.nodeType === 3 ? document.createTextNode(node.nodeValue) : node.cloneNode(false);
  let child = node.firstChild;
  while (child) {
    if (javascriptEnabled === true || child.nodeType !== 1 || child.nodeName !== 'SCRIPT') {
      clone.appendChild(cloneNode(child, javascriptEnabled));
    }
    child = child.nextSibling;
  }
  if (node.nodeType === 1) {
    if (node.nodeName === 'CANVAS') {
      clone.width = node.width;
      clone.height = node.height;
      clone.getContext('2d').drawImage(node, 0, 0);
    } else if (node.nodeName === 'TEXTAREA' || node.nodeName === 'SELECT') {
      clone.value = node.value;
    }
    clone.addEventListener(
      'load',
      () => {
        clone.scrollTop = node.scrollTop;
        clone.scrollLeft = node.scrollLeft;
      },
      true,
    );
  }
  return clone;
}

export function getFileBase64(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener('load', (e: ProgressEvent<FileReader>) => {
      if (e.target && e.target.result) {
        resolve(e.target.result);
      }
    });
    reader.addEventListener('error', err => {
      reject(err);
    });
  });
}

export function getWaterMark(text: string) {
  let canvas = document.createElement('canvas');
  canvas.height = 120;
  canvas.width = 250;
  let ctx = canvas.getContext('2d') as any;
  ctx.clearRect(0, 0, 200, 200); //绘制之前画布清除
  ctx.font = '100 13px Arial';
  ctx.fontKerning = 'none';
  ctx.fontStretch = 'ultra-condensed';
  ctx.rotate((-20 * Math.PI) / 180); //为了实现水印倾斜效果,旋转画布坐标系
  ctx.fillStyle = 'rgba(50,50,50,0.08)'; //画笔颜色
  ctx.fillText(text, -20, 80); //书写的内容及位置
  // ctx.rotate('20*Math.PI/180');  //坐标系还原,如果后续没有其他操作,这一步可以省略

  // 将canvas的内容转换为base64编码
  let data = canvas.toDataURL('image/png', 1); //1表示质量(无损压缩)

  // 将容器的的背景图片设置为生成的base64图片,并平铺
  return `url(${data}) repeat`;
}
