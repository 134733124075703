import { accountLogin, loginByOa, ssoLogin } from '@/services/login';
import { deleteCookie, loginOutCb, setCookie, setStore, getStore } from '@/utils/utils';
import { message } from 'antd';
import { EffectsCommandMap } from 'dva';
import { AnyAction, Reducer } from 'redux';
import router from 'umi/router';

export type Effect = (
  action: AnyAction,
  effects: EffectsCommandMap & { select: <T>(func: (state: {}) => T) => T },
) => void;

export interface LoginModelType {
  namespace: string;
  state: {
    isLogined: boolean;
  };
  effects: {
    accountLogin: Effect;
    loginByOa: Effect;
    logout: Effect;
  };
  reducers: {
    changeLoginStatus: Reducer<{}>;
  };
}

const Model: LoginModelType = {
  namespace: 'login',

  state: {
    isLogined: false,
  },

  effects: {
    *accountLogin({ payload }, { call, put }) {
      const { product_id, ...rest } = payload;
      // const res = yield call(accountLogin, rest);
      const res = yield call(ssoLogin, rest);
      if (res && +res.code === 0 && res.data.code === 1) {
        // const {
        //   data: { expire, uid, perms },
        //   data,
        // } = res;
        yield setStore('product_id', product_id);
        yield setStore('login_type', 'accout');
        yield setCookie('ida-token', res.data.token, 86400);
        // yield setCookie('ida-token', data['ida-token'], expire);
        // yield setCookie('uid', uid, expire);
        // yield setCookie('perms', String(perms), expire);
        message.success('登录成功！');
        yield put({
          type: 'global/changeproduct_id',
          payload: product_id,
        });
        yield put({
          type: 'changeLoginStatus',
          payload: true,
        });
        const lastPath = getStore('lastPath');
        router.replace(lastPath || '/');
      }
    },
    *loginByOa({ payload }, { call, put }) {
      const res = yield call(loginByOa, payload);
      if (res && +res.code === 0) {
        const {
          data: { uid, perms, defaultproduct_id },
        } = res;
        const expire = 60 * 60 * 8;
        !getStore('product_id') && setStore('product_id', defaultproduct_id);
        setCookie('ida-token', res.data['ida-token'], expire);
        setCookie('uid', uid, expire);
        setCookie('perms', String(perms), expire);
        setStore('login_type', 'oa');
        message.success('登录成功！');
        yield put({
          type: 'changeLoginStatus',
          payload: true,
        });
        const lastPath = getStore('lastPath');
        router.replace(lastPath || '/');
      } else {
        setTimeout(() => {
          router.replace('/login/by_oa');
        }, 500);
      }
    },
    *logout(_, { put }) {
      yield put({
        type: 'changeLoginStatus',
        payload: false,
      });
      loginOutCb();
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        isLogined: payload,
      };
    },
  },
};

export default Model;
