import request from '@/utils/request';
import { Params } from './index.d';
import { stringify } from 'qs';
import { getCookie } from '@/utils/utils';

/**
 * app管理--app列表
 */
export async function getAppManageList(params: Params): Promise<any> {
  return request.post('/api/metadata/product/list', {
    data: {
      ...params,
      ssoProductId: getCookie('sso-product-id'),
    },
  });
}

/**
 * app管理--新增app
 */
export async function addAppManage(params: Params): Promise<any> {
  return request.post('/api/metadata/product/add', {
    data: params,
  });
}

/**
 * app管理--删除app
 */
export async function removeAppManage(params: Params): Promise<any> {
  return request.post('/api/metadata/product/remove', {
    data: params,
  });
}

/**
 * app管理--更新app
 */
export async function updateAppManage(params: Params): Promise<any> {
  return request.post('/api/metadata/product/update', {
    data: params,
  });
}

/**
 * 事件分组管理--事件分组列表
 */
export async function getGroupList(params: Params): Promise<any> {
  return request.post('/api/metadata/event_module/list', {
    data: params,
  });
}

/**
 * 事件分组管理--删除事件分组
 */
export async function deleteGroupList(params: Params): Promise<any> {
  return request.post('/api/metadata/event_module/delete', {
    data: params,
  });
}

/**
 * 事件分组管理--更新事件分组
 */
export async function updateGroupList(params: Params): Promise<any> {
  return request.post('/api/metadata/event_module/update', {
    data: params,
  });
}

/**
 * 事件分组管理--新增事件分组
 */
export async function addGroupList(params: Params): Promise<any> {
  return request.post('/api/metadata/event_module/add', {
    data: params,
  });
}

/**
 * 用户属性管理-用户属性列表
 */
export async function getUserList(params: Params): Promise<any> {
  return request.post('/api/metadata/user_attr/list', {
    data: params,
  });
}

/**
 * 用户属性管理--添加用户属性
 */
export async function addUserList(params: Params): Promise<any> {
  return request.post('/api/metadata/user_attr/add', {
    data: params,
  });
}

/**
 * 用户属性管理-- 废止用户属性
 */
export async function removeUserList(params: Params): Promise<any> {
  return request.post('/api/metadata/user_attr/remove', {
    data: params,
  });
}

/**
 * 用户属性管理--更新用户属性
 */
export async function updateUserList(params: Params): Promise<any> {
  return request.post('/api/metadata/user_attr/update', {
    data: params,
  });
}

/**
 * 元数据管理--刷新元数据
 */
export async function updateMetadata(params: Params): Promise<any> {
  return request(`/api/trigger/update_metadata?${stringify(params)}`, {
    data: { noErrorMsg: true },
  });
}

/**
 * 事件属性管理--新增事件属性
 */
export async function addEventAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/add', {
    data: params,
  });
}

/**
 * 事件属性管理--属性列表
 */
export async function getEventAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/list', {
    data: params,
  });
}

/**
 * 事件属性管理--属性列表
 */
export async function updateEventAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/update', {
    data: params,
  });
}

/**
 * 事件属性管理--属性列表
 */
export async function removeEventAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/remove', {
    data: params,
  });
}

/**
 * 事件管理--事件列表
 */
export async function getEventManageList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/list', {
    data: params,
  });
}
/**
 * 事件管理--添加事件
 */
export async function addEventManageList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/add', {
    data: params,
  });
}
/**
 * 事件管理--更新事件
 */
export async function updateEventManageList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/update', {
    data: params,
  });
}
/**
 * 事件管理--事件复制
 */
export async function copyEventManage(params: Params): Promise<any> {
  return request.post('/api/sys/event/manage_copy', {
    data: params,
  });
}
/**
 * 事件管理--废止事件
 */
export async function removeEventManageList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/remove', { data: params });
}

/**
 * 事件管理--独有属性列表
 */
export async function getPrivateList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/private_attr_list', { data: params });
}

/**
 * 事件管理--行为列表
 */
export async function getActionList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/event_action_list', {
    data: params,
  });
}

/**
 * 事件管理--自定义字段名列表
 */
export async function getCustomFiledList(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/manage_column_name_list', { data: params });
}

/**
 * 虚拟事件管理--虚拟事件列表
 */
export async function fetchVireventList(params: Params): Promise<any> {
  return request.post('/api/metadata/event/vir_list', {
    data: params,
  });
}

/**
 * 虚拟事件管理--新增虚拟事件
 */
export async function addVirevent(params: Params): Promise<any> {
  return request.post('/api/metadata/event/vir_add', {
    data: params,
  });
}

/**
 * 虚拟事件管理--废止虚拟事件
 */
export async function removeVirevent(params: Params): Promise<any> {
  return request.post('/api/metadata/event/vir_remove', {
    data: params,
  });
}

/**
 * 虚拟事件管理--更新虚拟事件
 */
export async function updateVirevent(params: Params): Promise<any> {
  return request.post('/api/metadata/event/vir_update', {
    data: params,
  });
}

/**
 *基础指标--获取用户度量列表
 *
 * @export
 * @param {Params} params
 * @returns {Promise<any>}
 */
export async function getUserMeasureList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/user_measure_list', {
    data: params,
  });
}

/**
 * app配置组管理-列表
 */
export async function fetchGroupConfigList(params: Params): Promise<any> {
  return request(`/api/metadata/product_group/list?${stringify(params)}`);
}

/**
 * app配置组管理-新增
 */
export async function addGroupConfig(params: Params): Promise<any> {
  return request.post('/api/metadata/product_group/add', {
    data: params,
  });
}

/**
 * app配置组管理-更新
 */
export async function updateGroupConfig(params: Params): Promise<any> {
  return request.post('/api/metadata/product_group/update', {
    data: params,
  });
}

/**
 * app配置组管理-更新
 */
export async function deleteGroupConfig(params: Params): Promise<any> {
  return request.post('/api/metadata/product_group/delete', {
    data: params,
  });
}

/**
 * 事件管理--复制配置
 */
export async function manageUpdate(params: Params): Promise<any> {
  return request.post('/api/sys/event/manage_copy_config', {
    data: params,
  });
}

/**
 * 事件管理--配置覆盖
 */
export async function manageConfigCover(params: Params): Promise<any> {
  return request.post('/api/sys/event/manage_config_cover', {
    data: params,
  });
}

/**
 * 元数据-事件属性-获取函数列表
 */
export async function getFuncsList(): Promise<any> {
  return request.post('/api/metadata/attr/get_func_list', {});
}

/**
 * 元数据-订单属性-列表
 */
export async function getOrderAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/order_attr/list', { data: params });
}

/**
 * 元数据-订单属性-添加
 */
export async function addOrderAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/order_attr/add', { data: params });
}

/**
 * 元数据-订单属性-更新
 */
export async function updateOrderAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/order_attr/update', { data: params });
}

/**
 * 元数据-订单属性-废止
 */
export async function removeOrderAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/order_attr/remove', { data: params });
}

/**
 * 元数据-订单属性-列表
 */
export async function getGoodsAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/goods_attr/list', { data: params });
}

/**
 * 元数据-订单属性-添加
 */
export async function addGoodsAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/goods_attr/add', { data: params });
}

/**
 * 元数据-订单属性-更新
 */
export async function updateGoodsAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/goods_attr/update', { data: params });
}

/**
 * 元数据-订单属性-废止
 */
export async function removeGoodsAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/goods_attr/remove', { data: params });
}

/**
 * 元数据-内容属性-列表
 */
export async function getDimList(params: Params): Promise<any> {
  return request.post('/api/metadata/dim_tablename/list', { data: params });
}

/**
 * 内容属性管理-列表
 */
export async function getContentAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/content_attr/list', { data: params });
}

/**
 * 内容属性管理-新增
 */
export async function addContentAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/content_attr/add', { data: params });
}

/**
 * 内容属性管理-编辑
 */
export async function updateContentAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/content_attr/update', { data: params });
}

/**
 * 内容属性管理-删除
 */
export async function removeContentAttrList(params: Params): Promise<any> {
  return request.post('/api/metadata/content_attr/remove', { data: params });
}

/**
 * 基础指标--获取关联属性
 */
export async function getRelevanceList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/relevance_list', { data: params });
}

/**
 * 元数据-获取执行ck的语句列表
 */
export async function getExecuteCKSqls(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/get_excute_ck_sqls', { data: params });
}

/**
 * 元数据-执行ck的语句列表
 */
export async function executeCKSqls(params: Params): Promise<any> {
  return request.post('/api/metadata/attr/excute_ck_sqls', { data: params });
}
