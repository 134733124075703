import { Reducer } from 'redux';
import { Model } from 'dva';
import {
  fetchTemplateList,
  fetchTypeList,
  addTemplate,
  updateTemplate,
  removeTemplate,
  fetchTargetList,
  updateTargetList,
  addTargetList,
  removeTargetList,
  fetchIndicatorList,
  // fetchIndicatorDetail,
  addIndicatorDetail,
  updateIndicator,
  removeIndicator,
} from '@/services/modal';
import { IIndicatorTypeList } from './modal.d';
import { IEffect } from './connect.d';
import { message } from 'antd';

export interface ModalState {
  template_list: {};
  type_list: {}[];
  target_list: IIndicatorTypeList[];
  indicator_list: {}[];
}

export interface ModalType extends Model {
  namespace: string;
  state: ModalState;
  effects: {
    fetchTemplateList: IEffect;
    fetchTypeList: IEffect;
    addTemplate: IEffect;
    updateTemplate: IEffect;
    removeTemplate: IEffect;
    fetchTargetList: IEffect;
    updateTargetList: IEffect;
    addTargetList: IEffect;
    removeTargetList: IEffect;
    fetchIndicatorList: IEffect;
    // fetchIndicatorDetail: IEffect;
    addIndicatorDetail: IEffect;
    updateIndicator: IEffect;
    removeIndicator: IEffect;
  };
  reducers: {
    saveTemplateList: Reducer<ModalState>;
    saveTypeList: Reducer<ModalState>;
    saveTargetList: Reducer<ModalState>;
    saveIndicatorList: Reducer<ModalState>;
  };
}

const initState: ModalState = {
  template_list: {},
  type_list: [],
  target_list: [],
  indicator_list: [],
};

const MenuModel: ModalType = {
  namespace: 'modal',

  state: initState,

  effects: {
    *fetchTemplateList({ payload }, { call, put }) {
      const res = yield call(fetchTemplateList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTemplateList',
          payload: res.data,
        });
      }
    },
    *fetchTypeList({ payload }, { call, put }) {
      const res = yield call(fetchTypeList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTypeList',
          payload: res.data,
        });
      }
    },
    *addTemplate({ payload, callback }, { call }) {
      const res = yield call(addTemplate, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateTemplate({ payload, callback }, { call }) {
      const res = yield call(updateTemplate, payload);
      if (res && +res.code === 0) {
        message.success('编辑成功！');
        callback();
      }
    },
    *removeTemplate({ payload, callback }, { call }) {
      const res = yield call(removeTemplate, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
    *fetchTargetList({ payload }, { call, put }) {
      const res = yield call(fetchTargetList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveTargetList',
          payload: res.data,
        });
      }
    },
    *addTargetList({ payload, callback }, { call }) {
      const res = yield call(addTargetList, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateTargetList({ payload, callback }, { call }) {
      const res = yield call(updateTargetList, payload);
      if (res && +res.code === 0) {
        message.success('编辑成功！');
        callback();
      }
    },
    *removeTargetList({ payload, callback }, { call }) {
      const res = yield call(removeTargetList, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
    *fetchIndicatorList({ payload }, { call, put }) {
      const res = yield call(fetchIndicatorList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveIndicatorList',
          payload: res.data,
        });
      }
    },
    *addIndicatorDetail({ payload, callback }, { call }) {
      const res = yield call(addIndicatorDetail, payload);
      if (res && +res.code === 0) {
        message.success('添加成功！');
        callback();
      }
    },
    *updateIndicator({ payload, callback }, { call }) {
      const res = yield call(updateIndicator, payload);
      if (res && +res.code === 0) {
        message.success('编辑成功！');
        callback();
      }
    },
    *removeIndicator({ payload, callback }, { call }) {
      const res = yield call(removeIndicator, payload);
      if (res && +res.code === 0) {
        message.success('删除成功！');
        callback();
      }
    },
  },

  reducers: {
    saveTemplateList(state = initState, { payload }) {
      return {
        ...state,
        template_list: payload,
      };
    },
    saveTypeList(state = initState, { payload }) {
      return {
        ...state,
        type_list: payload,
      };
    },
    saveTargetList(state = initState, { payload }) {
      return {
        ...state,
        target_list: payload,
      };
    },
    saveIndicatorList(state = initState, { payload }) {
      return {
        ...state,
        indicator_list: payload,
      };
    },
  },
};

export default MenuModel;
