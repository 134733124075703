import request from '@/utils/request';
import { stringify } from 'qs';
import { Params } from './index.d';

export async function fetchEventMenuList(params: Params): Promise<any> {
  return request(`/api/metadata/basic_index/event_menu_list?${stringify(params)}`);
}

export async function fetchEventMeasureList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/auto_metric_list', {
    data: params,
  });
}

export async function fetchEventFilterList(params: Params): Promise<any> {
  return request.post(`/api/metadata/basic_index/event_filter_list?${stringify(params)}`, {
    data: params,
  });
}

export async function fetchCalFieldFilterList(params: Params): Promise<any> {
  return request.post(`/api/metadata/basic_index/cal_field_filter_list?${stringify(params)}`, {
    data: params,
  });
}

export async function fetchEventGroupList(params: Params): Promise<any> {
  return request.post(`/api/metadata/basic_index/event_group_list?${stringify(params)}`, {
    data: params,
  });
}

export async function fetchCrowdFilterList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/crowd_filter_list', {
    data: params,
  });
}

export async function fetchFunnelList(params: Params): Promise<any> {
  return request.post(`/api/funnel/list?${stringify(params)}`, {
    data: params,
  });
}

export async function addFunnel(params: Params): Promise<any> {
  return request.post('/api/funnel/add', {
    data: params,
  });
}

export async function updateFunnel(params: Params): Promise<any> {
  return request.post('/api/funnel/update', {
    data: params,
  });
}

export async function deleteFunnel(params: Params): Promise<any> {
  return request.post('/api/funnel/delete', {
    data: params,
  });
}

export async function fetchFunnelById(params: Params): Promise<any> {
  return request.post('/api/funnel/id', {
    data: params,
  });
}

export async function fetchEventReport(params: Params): Promise<any> {
  return request.post('/api/analysis/events/report', {
    data: params,
  });
}

export async function fetchKeepReport(params: Params): Promise<any> {
  return request.post('/api/analysis/retention/report', {
    data: params,
  });
}

export async function fetchFunnelReport(params: Params): Promise<any> {
  return request.post('/api/analysis/funnel/report', {
    data: params,
  });
}

export async function fetchAddictionReport(params: Params): Promise<any> {
  return request.post('/api/analysis/addiction/report', {
    data: params,
  });
}

export async function cancelReport(params: Params): Promise<any> {
  return request.post('/api/analysis/cancel', {
    data: params,
  });
}

export async function fetchAllValue(params: Params): Promise<any> {
  return request.post('/api/analysis/download', {
    data: params,
  });
}

export async function checkIndicator(params: Params): Promise<any> {
  return request.post('/api/dashboard/template/check_indicator', {
    data: params,
  });
}

export async function getCalFieldList(params: Params): Promise<any> {
  return request.post('/api/metadata/basic_index/cal_field_list', {
    data: params,
  });
}

export async function mockReport(params: Params): Promise<any> {
  return request.post('https://apidoc.seeyouyima.com/client/5eba3c6f423ddf1bfa1e5c63', {
    data: params,
  });
}

export async function DownloadUserTable(params: Params): Promise<any> {
  return request.post('/api/user_analysis/table/download', {
    data: params,
  });
}

export async function DownloadContentTable(params: Params): Promise<any> {
  return request.post('/api/content_analysis/table/download', {
    data: params,
  });
}
