import request from '@/utils/request';
import { stringify } from 'qs';
import { Params } from './index.d';

export async function fetchMenuList(): Promise<any> {
  return request('/api/sys/menu/list');
}

export async function updateMenu(params: Params): Promise<any> {
  return request.post('/api/sys/menu/update', {
    data: params,
  });
}

export async function addMenu(params: Params): Promise<any> {
  return request.post('/api/sys/menu/save', {
    data: params,
  });
}

export async function deleteMenu(params: Params): Promise<any> {
  return request(`/api/sys/menu/remove?${stringify(params)}`);
}
