import { Reducer } from 'redux';
import { Model } from 'dva';
import { message } from 'antd';
import { fetchMenuList, addMenu, updateMenu, deleteMenu } from '@/services/menu';
import { IEffect } from './connect.d';

export interface MenuState {
  menu_list: [];
}

export interface MenuType extends Model {
  namespace: string;
  state: MenuState;
  effects: {
    fetchMenuList: IEffect;
    addMenu: IEffect;
    updateMenu: IEffect;
    deleteMenu: IEffect;
  };
  reducers: {
    saveMenuList: Reducer<MenuState>;
  };
}

const initState: MenuState = {
  menu_list: [],
};

const MenuModel: MenuType = {
  namespace: 'menu',

  state: initState,

  effects: {
    *fetchMenuList({ payload }, { call, put }) {
      const res = yield call(fetchMenuList, payload);
      if (res && +res.code === 0) {
        yield put({
          type: 'saveMenuList',
          payload: res.data,
        });
      }
    },
    *addMenu({ payload, callback }, { call }) {
      const res = yield call(addMenu, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *updateMenu({ payload, callback }, { call }) {
      const res = yield call(updateMenu, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
    *deleteMenu({ payload, callback }, { call }) {
      const res = yield call(deleteMenu, payload);
      if (res && +res.code === 0) {
        message.success(res.msg);
        typeof callback === 'function' && callback(res.data);
      }
    },
  },

  reducers: {
    saveMenuList(state = initState, { payload }) {
      return {
        ...state,
        menu_list: payload,
      };
    },
  },
};

export default MenuModel;
